import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useData } from "../../context";
import { ApiConfig } from "../../api/apiConfig/apiConfig";
import AuthService from "../../api/services/AuthService";

export const Sidebar = () => {

  const { userName, rank, setRank, setUserName, setEmail, setMobileNo, setName, setSponserId, setDailyCapping, toggle} = useData();
  const [profilePic, setProfilePic] = useState([]);

  useEffect(() => {
    getProfileData();
    getDailyCapping();
  }, []);

  const getProfileData = async () => {
    await AuthService.getuserProfile().then((result) => {
      if (result?.success) {
        try {
          setName(result?.data?.fullName);
          setMobileNo(result?.data?.mobileNumber);
          setEmail(result?.data?.email);
          setUserName(result?.data?.username);
          setRank(result.data.current_rank);
          setProfilePic(result?.data?.profilepicture);
          setSponserId(result?.data?.referral_code);
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(result.message);
      }
    });
  };

  const getDailyCapping = async () => {
    await AuthService.getDailyCapping().then(async (result) => {
      if (result.success) {
        try {
          setDailyCapping(result?.data.total_capping.toFixed(2));
        } catch {
          console.log(result.message);
        }
      } else {
        console.log(result.message);
      }
    });
  };

  return (
    <nav id="sidebar" className={toggle ? "shrinked" : ""}>
      <div className="sidebar-header d-flex align-items-center p-4 ">
        {!profilePic ? (
          <img style={{ width: "40px" }} src="img/profilelogo.png" alt="user avatar" />
        ) : (
          <img style={{ width: "40px" }} className="avatar shadow-0 img-fluid rounded-circle" src={`${ApiConfig.baseUrl + profilePic}`} alt="user avatar" />
        )}
        <div className="ms-3 title">
          <h1 className="h5 mb-1">
            {userName}<small>[{rank === 'unranked' ? 0 : rank}]</small>
          </h1>
        </div>
      </div>
      <ul className="list-unstyled">
        <li className="sidebar-item active">
          <Link to="/dashboard" className="sidebar-link">
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <i className="ri-home-2-line"></i>
            </span>
            <span>Dashboard </span>
          </Link>
        </li>
        <li className="sidebar-item">
          <a className="sidebar-link" href="#exampledropdownDropdown2" data-bs-toggle="collapse">
            {" "}
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <i className="ri-arrow-right-up-fill"></i>
            </span>
            <span>My Package </span>
          </a>
          <ul className="collapse list-unstyled " id="exampledropdownDropdown2">
            <li><Link to="/StakeWithDApp" className="sidebar-link">Package Clubs</Link></li>
            <li><Link to="/packageJoiningReport" className="sidebar-link">{" "}Package Joining Report</Link>
            </li>
          </ul>
        </li>
        <li className="sidebar-item">
          <a className="sidebar-link" href="#exampledropdownDropdown3" data-bs-toggle="collapse">
            {" "}
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <i className="ri-user-line"></i>
            </span>
            <span>Profile</span>
          </a>
          <ul className="collapse list-unstyled " id="exampledropdownDropdown3">
            <li><Link to="/myprofile" className="sidebar-link"> My Profile </Link> </li>
            <li><Link to="/editProfile" className="sidebar-link">Edit Profile</Link></li>
            <li><Link to="/changepassword" className="sidebar-link">Change Password</Link></li>
          </ul>
        </li>
        <li className="sidebar-item">
          <a className="sidebar-link" href="#exampledropdownDropdown4" data-bs-toggle="collapse" >
            {" "}
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <i className="ri-group-line"></i>
            </span>
            <span>Team</span>
          </a>
          <ul className="collapse list-unstyled " id="exampledropdownDropdown4">
            <li>
              <Link to="/treeView" className="sidebar-link">
                Tree View
              </Link>
            </li>
            <li>
              <Link to="/teamViewReport" className="sidebar-link">
                Team View Report
              </Link>
            </li>
            <li>
              <Link to="/dailyBusinessReport" className="sidebar-link">
                Daily Business Report
              </Link>
            </li>
            <li>
              <Link to="/referalUserList" className="sidebar-link">
                Referal User List
              </Link>
            </li>
            <li>
              <Link to="/rankAchievement" className="sidebar-link">
                Rank Achievement in my Team
              </Link>
            </li>
          </ul>
        </li>
        <li className="sidebar-item">
          <a className="sidebar-link" href="#exampledropdownDropdown5" data-bs-toggle="collapse" >
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <i className="ri-money-dollar-circle-fill"></i>
            </span>
            <span>Income </span>
          </a>
          <ul className="collapse list-unstyled " id="exampledropdownDropdown5">
            <li>
              <Link to="/staking_income" className="sidebar-link">
                Staking Income
              </Link>
            </li>
            <li>
              <Link to="/sponser_income" className="sidebar-link">
                Sponser Income
              </Link>
            </li>

            <li>
              <Link to="/matching_income" className="sidebar-link">
                Matching Income
              </Link>
            </li>

            <li>
              <Link to="/matching_level_income" className="sidebar-link">
                Matching Level Income
              </Link>
            </li>

            <li>
              <Link to="/reward_income" className="sidebar-link">
                Reward Income
              </Link>
            </li>

            <li>
              <Link to="/promotion_rank" className="sidebar-link">
                Promotion Rank
              </Link>
            </li>

            <li>
              <Link to="/allTransactions" className="sidebar-link">
                All Transactions
              </Link>
            </li>

          </ul>
        </li>

        <li className="sidebar-item">
          <a className="sidebar-link" href="#exampledropdownDropdown6" data-bs-toggle="collapse">
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <i className="ri-money-dollar-circle-fill"></i>
            </span>
            <span>Withdraw</span>
          </a>
          <ul className="collapse list-unstyled" id="exampledropdownDropdown6">
            <li>
              <Link to="/withdraw" className="sidebar-link">
                Withdraw
              </Link>
            </li>
            <li>
              <Link to="/withdrawalHistory" className="sidebar-link">
                Withdraw Report
              </Link>
            </li>
          </ul>
        </li>
        <li className="sidebar-item">
          <Link to="/support" className="sidebar-link">
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <i className="ri-headphone-line"></i>
            </span>
            <span>Support </span>
          </Link>
        </li>
      </ul>
    </nav>
  );
};
