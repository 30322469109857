import { Header, Sidebar, Footer } from "../../components";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ApiConfig } from "../../api/apiConfig/apiConfig";
import { useData } from "../../context";
import "react-image-crop/dist/ReactCrop.css";
import AuthService from "../../api/services/AuthService";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../../components/CustomAlertMessage";
import LoaderHelper from "../../components/Loading/LoaderHelper";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { default as Web3 } from "web3";

import Web3Modal from "web3modal";
export const EditProfile = () => {
  const [localSelfy, setLocalSelfy] = useState("");
  const [profilePic, setProfilePic] = useState([]);
  const [myfile, setMyfile] = useState();
  const [isShow, setIsShow] = useState(false);
  useEffect(() => {
    getProfileData();
  }, []);
  const {
    email,
    setEmail,
    name,
    setName,
    mobileNo,
    setMobileNo,
    senderAddress,
    setSenderAddress,
    // profilePic,
    // setProfilePic,
    setLoginId,
    setUserName,
    // setWalletAddress,
    // walletAddress,
  } = useData();
  console.log(senderAddress, "senderAddress");
  const handleChangeSelfie = async (event) => {
    event.preventDefault();
    const fileUploaded = event.target.files[0];
    const imgata = URL.createObjectURL(fileUploaded);
    setMyfile(fileUploaded);
    setLocalSelfy(imgata);
  };

   const [walletAddress, setWalletAddress] = useState("");

  console.log(walletAddress);

  console.log(localSelfy, "localSelfy");

  const handleProfile = async (
    name,
    email,
    mobileNo,
    myfile,
    walletAddress
  ) => {
    var formData = new FormData();
    formData.append("profilepicture", myfile);
    formData.append("username", name);
    formData.append("mobileNumber", mobileNo);
    formData.append("email", email);
    formData.append("wallet_address", walletAddress)
    await AuthService.editprofile(formData).then((result) => {
      if (result.success) {
        try {
          // alertSuccessMessage(result.message);
          setIsShow(true);
          getProfileData();
          //window.location.reload(false);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  console.log(profilePic, "dssd");

  const getProfileData = async () => {
    await AuthService.getuserProfile().then((result) => {
      if (result?.success) {
        try {
          // alertSuccessMessage(result?.message);
          setName(result?.data?.fullName);
          setMobileNo(result?.data?.mobileNumber);
          setEmail(result?.data?.email);
          setUserName(result?.data?.username);
          setProfilePic(result?.data?.profilepicture);
          console.log(result?.data?.profilepicture, "profilePiccccc");
          // setLoginId(result?.data?.loginid);
        } catch {}
      } else {
      }
    });
  };
  // const [senderAddress, setSenderAddress] = useState("");

  console.log(senderAddress, "senderAddressR");

  var web3;

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        rpc: {
          56: "https://bsc-dataseed.binance.org/",
        },
      },
    },
  };

  const web3Modal = new Web3Modal({
    network: "binance",
    cacheProvider: true,
    providerOptions,
  });

  const WalletConnect = async () => {
    const provider = await web3Modal.connect();
    web3 = new Web3(provider);
    const accounts = await web3.eth.getAccounts();
    var account = accounts[0];
    setSenderAddress(accounts[0]);
  };

  return (
    <>
      <div className="wrapper">
        <Header />

        <div className="d-flex align-items-stretch">
          {/* Sidebar Navigation */}
          <Sidebar />
          <div className="page-content">
            {/* Page Header */}
            <div className="bg-dash-dark-2 py-4">
              <div className="container-fluid">
                <h2 className="h5 mb-0">Profile</h2>{" "}
              </div>
            </div>
            <div className="container-fluid py-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb py-3 px-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Profile
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Profile
                  </li>
                </ol>
              </nav>
            </div>
            <section className="pt-0">
              <div className="container-fluid">
                <div className="row gy-4">
                  {/* Basic Form */}
                  <div className="col-lg-6 m-auto">
                    <div className="card  h-100">
                      <div className="card-header">
                        <h3 className="h4 mb-0">Member Information</h3>{" "}
                      </div>

                      {/* <div>
                        <h3 class="h4 mb-0">Select Buy Package Details</h3>
                        {!senderAddress ? (
                          <button
                            class="btn btn-primary card-header"
                            type="button"
                            style={{ float: "right" }}
                            onClick={() => WalletConnect()}
                          >
                            Wallet Connect
                          </button>
                        ) : (
                          <button
                            class="btn btn-success card-header  "
                            type="button"
                            style={{ float: "right" }}
                          >
                            Connected
                          </button>
                        )}
                      </div> */}
                      {/* <button
                        class="btn btn-primary "
                        type="button"
                        onClick={() => WalletConnect()}
                      >
                        Wallet Connect
                      </button> */}

                      <div className="card-body pt-0">
                        <form>
                          <div className="col-md-12">
                            {/* <div>
                              <img
                                className="avatar shadow-0 img-fluid rounded-circle"
                                src="img/profilelogo.png"
                                alt=""
                              />
                            </div> */}
                            <div className="avatar-upload">
                              <div className="avatar-edit ">
                                <input
                                  type="file"
                                  id="imageUpload"
                                  name="profilepicture"
                                  onChange={handleChangeSelfie}
                                />
                                <label htmlFor="imageUpload">
                                  <i class="ri-pencil-line"></i>
                                </label>
                              </div>
                              <div className="avatar-preview ">
                                {profilePic === "" ? (
                                  <img src="img/profilelogo.png" />
                                ) : (
                                  <img
                                    className="avatar shadow-0 img-fluid rounded-circle"
                                    src={
                                      !localSelfy
                                        ? `${
                                            ApiConfig.baseUrl 
                                            +
                                            profilePic
                                          }`
                                        : localSelfy
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="exampleInputEmail1"
                            >
                              Email ID
                            </label>
                            <input
                              className="form-control"
                              id="exampleInputEmail1"
                              type="email"
                              aria-describedby="Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="exampleInputName"
                            >
                              Username
                            </label>
                            <input
                              className="form-control"
                              id="exampleInputName"
                              // type="text"
                              aria-describedby="Name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="exampleInputMobile"
                            >
                              Mobile No
                            </label>
                            <input
                              className="form-control"
                              id="exampleInputMobile"
                              type="tel"
                              aria-describedby="Mobile"
                              value={mobileNo}
                              onChange={(e) => setMobileNo(e.target.value)}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="exampleInputMobile"
                            >
                              Wallet Address
                            </label>
                            <input
                              className="form-control"
                              id="exampleInputMobile"
                              type="text"
                              value={walletAddress}
                              onChange={(e) => setWalletAddress(e.target.value)}
                            />
                          </div>

                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={(e) => {
                              {
                                e.preventDefault();
                                handleProfile(
                                  name,
                                  email,
                                  mobileNo,
                                  myfile,
                                  walletAddress
                                );
                              }
                            }}
                          >
                            Update Profile
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Page Footer */}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
