import React from "react";
import { Header, Sidebar, Footer } from "../../components";

import { Link } from "react-router-dom";
import LoaderHelper from "../../components/Loading/LoaderHelper";
import AuthService from "../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../components/CustomAlertMessage";
import { useData } from "../../context";
import { useEffect, useState } from "react";
import { ApiConfig } from "../../api/apiConfig/apiConfig";

export const TreeView = () => {
  const loginId = localStorage.getItem("userId");
  const [levelData, setLevelData] = useState([]);

  useEffect(() => {
    levelUserList1(loginId)
  }, [])
  const uniqueData = levelData.filter((item, index, self) => {
    return index === self.findIndex((t) => (
      t.rootLoginid === item.rootLoginid
    ));
  });



  const levelUserList1 = async (loginId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.levelUserList(loginId).then(async (result) => {
      if (result?.success) {
        try {
          LoaderHelper.loaderStatus(false);
          setLevelData(oldArray => [...new Set([...oldArray, result])]);
      
        } catch {
          LoaderHelper.loaderStatus(false);
        }
      }
      else {
        LoaderHelper.loaderStatus(false);
      }
    });
  };
  const handleLevels =()=>{
    return(
  
      uniqueData?.map((data) => {
      return (
        <div className="tree_row" >
          <div className="user_cass">
            <img src={data?.rootprofilepicture ? `${ApiConfig.baseUrl + data?.rootprofilepicture}` : "img/profilelogo.png"} alt="" />
            <span>{data?.rootUsername} </span>
           {/*  <small>ID: {data?.rootLoginid} </small> */}
          </div>
          <ul className="tree_ul" >
            {data?.data?.map((data) => {
              return (<>
                <li onClick={() => levelUserList1(data?.loginid)}
                >
                  <div className="user_cass addedCss">
                    <img src={data?.profilepicture ? `${ApiConfig.baseUrl + data?.profilepicture}` : "img/profilelogo.png"} alt="" />
                    <span>  {data?.username} </span>
                  {/*   <small> ID: {data?.loginid} </small> */}
                    <small> level: {data?.level} </small>
                  </div>
                </li>
              </>)
            })}
  
          </ul>
        </div>
      )
    }))}





  return (
    <>
    <div className="wrapper">
        <Header />
        <div className="d-flex align-items-stretch">
          {/* Sidebar Navigation */}
          <Sidebar />
          <div className="page-content">
            {/* Page Header */}
            <div className="bg-dash-dark-2 py-4">
              <div className="container-fluid">
                <h2 className="h5 mb-0">Tree View</h2>{" "}
              </div>
            </div>
            <div className="container-fluid py-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb py-3 px-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Tree View
                  </li>
                </ol>
              </nav>
            </div>


            <section className="pt-0">
              <div className="container-fluid" />
              <div className="row gy-4">
                {/* Basic Form */}
                <div className="col-lg-10 m-auto">
                  <div className="card  h-100">
                    <div className="card-header">

                      <div className="card-body p-0">

                        <div className="tree_view_modal" >
                            {handleLevels()}                         
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>



            {/* Page Footer */}
            <Footer />
          </div>
          </div>
          </div>
    </>
  );
};
