import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AuthService from "../../api/services/AuthService";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../../components/CustomAlertMessage";
import LoaderHelper from "../../components/Loading/LoaderHelper";
import { useData } from "../../context";

export const Signup = () => {
  const ref = window.location.href.split("=")[1];
  console.log(ref, "ref");

  const {
    setUserName,
    userName,
    email,
    setEmail,
    name,
    setName,
    mobileNo,
    setMobileNo,
    countryValue,
    setCountryValue,
  } = useData();

  const [passwordType, setPasswordType] = useState("password");
  const [reEnterPasswordType, setReEnterPasswordType] = useState("password");
  const [password, setPassword] = useState("");
  const [value, setValue] = useState(+91);
  // const [countryValue, setCountryValue] = useState("IN");
  const [sponserId, setSponserId] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [timer, settimer] = useState(30);
  const [disableBtn, setdisableBtn] = useState(false);
  const [otp, setOtp] = useState("");
  const [isShow, setIsShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  var countdown;

  const navigate = useNavigate();

  const countryCodes = require("country-codes-list");

  const myCountryCodesObject = countryCodes.customList(
    "countryCode",
    "+{countryCallingCode}"
  );

  const countryCode = Object.values(myCountryCodesObject);

  console.log(countryCode);

  const changeHandler = (e) => {
    setValue(e.target.value);
    console.log(value);
  };

  const countryHandler = (e) => {
    setCountryValue(e.target.value);
    console.log(countryValue);
  };

  // const country = Object.keys(myCountryCodesObject);

  const countryList = require("country-list");
  console.log(Object.keys(countryList.getNameList()));

  const finalCountryName = Object.keys(countryList.getNameList());

  // const { getCode, getName } = require("country-list");
  // console.log(getName(finalCountry));

  useEffect(() => {
    if (ref) {
      setSponserId(ref);
    }
  }, []);

  const handleRegister = async (
    sponserId,
    userName,
    fullName,
    value,
    countryValue,
    mobileNo,
    email,
    password,
    cPassword,
    otp
  ) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.register(
      sponserId,
      userName,
      fullName,
      value,
      countryValue,
      mobileNo,
      email,
      password,
      cPassword,
      otp
    ).then(async (result) => {
      console.log(result);
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          alertSuccessMessage(result.message);
          // setuserI(result.success.loginid);
          navigate("/login");
          setIsShow(false);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const handleGetOtp = async (email) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getOtp(email).then(async (result) => {
      console.log(result);
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          alertSuccessMessage(result.message);
          resendOtp();
          setIsShow(true);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };
  const resendOtp = () => {
    setdisableBtn(true);
    countdown = setInterval(countDownFunc, 1000);
    setTimeout(() => {
      setdisableBtn(false);
      settimer(30);
      clearInterval(countdown);
    }, 30000);
  };
  const countDownFunc = () => {
    settimer((timer) => timer - 1);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      return setPasswordType("text");
    }
    return setPasswordType("password");
  };

  const toggleConfirmPassword = () => {
    if (reEnterPasswordType === "password") {
      return setReEnterPasswordType("text");
    }
    return setReEnterPasswordType("password");
  };

  return (
    <div className="login-page">
      <div className="container  d-flex align-items-center position-relative py-5">
        <div className="card w-100 rounded overflow-hidden bg-none">
          <div className="card-body p-0">
            <div className="row gx-0 align-items-stretch">
              {/* Logo & Information Panel */}
              <Link to="/" className="col-12 text-center mb-5">
                <img className="login_logo" src="img/logo.png" alt="" />
              </Link>
              <div className="login-title text-center text-white mb-4">
                <h2>Create New Account</h2>
                <p>Enter details to Register on Almighty Token.</p>
              </div>
              <div className="login_card p-md-5 p-3 bg-dash-dark-2">
                <form className="login-form py-3 w-100">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-material-group mb-3">
                        <input
                          className="input-material"
                          id="login-username"
                          type="text"
                          name="loginUsername"
                          //autoComplete="off"
                          // data-validate-field="loginUsername"
                          // placeholder="Sponser Id"
                          value={ref ? ref : sponserId}
                          onChange={(e) => setSponserId(e.target.value)}
                        />
                        <label className="label-material" for="loginPassword">
                          Sponsor Id
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-material-group mb-3">
                        <input
                          className="input-material"
                          id="login-username"
                          type="text"
                          name="loginUsername"
                          autoComplete="off"
                          required
                          // data-validate-field="loginUsername"
                          //placeholder="Enter User Name"
                          onChange={(e) => setUserName(e.target.value)}
                        />
                        <label className="label-material" for="loginPassword">
                          User Name
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-material-group mb-3">
                        <input
                          className="input-material"
                          id="login-username"
                          type="text"
                          name="loginUsername"
                          autoComplete="off"
                          required
                          // data-validate-field="loginUsername"
                          //placeholder="Enter Full Name"
                          onChange={(e) => setName(e.target.value)}
                        />
                        <label className="label-material" for="loginPassword">
                          Full Name
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-material-group opt_btn_group mb-3">
                        <input
                          className="input-material"
                          id="login-username"
                          type="email"
                          name="loginUsername"
                          autoComplete="off"
                          required
                          // data-validate-field="loginUsername"
                          //placeholder="Enter Email Address"
                          onChange={(e) => setEmail(e.target.value)}
                        />{" "}
                        {disableBtn ? (
                          <button
                            disabled
                            type="button"
                            className="btn opt_btn btn-primary btn-sm"
                            style={{ color: "black" }}
                          >
                            Resend OTP {timer}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn opt_btn btn-primary btn-sm"
                            onClick={() => handleGetOtp(email)}
                          >
                            Verify
                          </button>
                        )}
                        <label className="label-material" for="login-username">
                          Email Address
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-material-group mb-3">
                        <input
                          className="input-material"
                          id="login-username"
                          type="text"
                          name="loginUsername"
                          autoComplete="off"
                          required
                          // data-validate-field="loginUsername"
                          //placeholder="Enter Email Otp"
                          onChange={(e) => setOtp(e.target.value)}
                        />
                        <label className="label-material" for="loginPassword">
                          Email Otp
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="input-material-group mb-3">
                        <select
                          className="input-material"
                          name="countryCode"
                          id=""
                          defaultValue={finalCountryName[104]}
                          onChange={countryHandler}
                        >
                          <option>Select Country</option>
                          {finalCountryName.map((option) => (
                            <option>{option}</option>
                          ))}
                        </select>
                        <label className="label-material" for="loginPassword">
                          Country
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-material-group mb-3">
                        <select
                          className="input-material"
                          name="countryCode"
                          id=""
                          onChange={changeHandler}
                          defaultValue={countryCode[90]}
                        >
                          {countryCode.map((option) => (
                            <option>{option}</option>
                          ))}
                        </select>
                        <label className="label-material" for="loginPassword">
                          Country Code
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-material-group mb-3">
                        <input
                          className="input-material"
                          id="login-username"
                          type="tel"
                          name="loginUsername"
                          autoComplete="off"
                          required
                          // data-validate-field="loginUsername"
                          //placeholder="Enter Mobile Number"
                          onChange={(e) => setMobileNo(e.target.value)}
                        />
                        <label className="label-material" for="loginPassword">
                          Mobile Number
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-material-group mb-3 opt_btn_group">
                        <input
                          className="input-material"
                          id="login-password"
                          type={passwordType}
                          name="loginPassword"
                          autoComplete="off"
                          required
                          // data-validate-field="loginPassword"
                          //placeholder="Enter Password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <a
                          className="show_password opt_btn btn-sm"
                          onClick={togglePassword}
                        >
                          {passwordType === "password" ? (
                            <i className="ri-eye-off-line" />
                          ) : (
                            <i className="ri-eye-line" />
                          )}
                        </a>
                        <label className="label-material" for="loginPassword">
                          Password
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-material-group mb-3 opt_btn_group">
                        <input
                          className="input-material"
                          id="login-password"
                          type={reEnterPasswordType}
                          name="loginPassword"
                          required
                          // data-validate-field="loginPassword"
                          //placeholder="Confirm Password"
                          onChange={(e) => setCPassword(e.target.value)}
                        />
                        <a
                          className="show_password opt_btn btn-sm "
                          onClick={toggleConfirmPassword}
                        >
                          {reEnterPasswordType === "password" ? (
                            <i className="ri-eye-off-line" />
                          ) : (
                            <i className="ri-eye-line" />
                          )}
                        </a>
                        <label className="label-material" for="loginPassword">
                          Re-Enter Password
                        </label>
                      </div>
                    </div>
                    {/* <div className="col-lg-6">
                      <div className="input-material-group mb-3">
                        <input
                          className="input-material"
                          id="login-password"
                          type="password"
                          name="loginPassword"
                          required
                          data-validate-field="loginPassword"
                          placeholder="Enter Transaction Password"
                          onChange={(e) => setTPassword(e.target.value)}
                        />
                      </div>
                    </div> */}
                  </div>

                  <div className="form-check mb-4">
                    <input
                      className="form-check-input"
                      id="register-agree"
                      name="registerAgree"
                      type="checkbox"
                      required
                      value={isChecked}
                      onChange={() => setIsChecked((isChecked) => !isChecked)}
                      data-validate-field="registerAgree"
                    />

                    <label
                      className="form-check-label form-label"
                      htmlFor="register-agree"
                    >
                      I agree with the terms and policy{" "}
                      {!isChecked ? (
                        showMessage ? (
                          <div style={{ color: "red", fontWeight: "bold" }}>
                            Please check this box if you want to proceed.
                          </div>
                        ) : (
                          " "
                        )
                      ) : (
                        " "
                      )}
                    </label>
                  </div>

                  <div className="d-block text-center">
                    <button
                      className="btn btn-primary mb-3 px-5  min-300"
                      id="Register"
                      type="button"
                      onClick={() => {
                        {
                          isChecked
                            ? handleRegister(
                                sponserId,
                                userName,
                                name,
                                value,
                                countryValue,
                                mobileNo,
                                email,
                                password,
                                cPassword,
                                otp
                              )
                            : setShowMessage((showMessage) => !showMessage);
                        }
                      }}
                    >
                      Create a New Account
                    </button>
                    <br />
                    <Link
                      to="/login"
                      className="btn btn btn-primary ww-50 btn-outline-primary border-gradient px-5 min-300"
                      id="login"
                    >
                      Back to Login
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
