import { Routes, Route } from "react-router-dom";
import React from "react";
import {
  LandingPage,
  Login,
  Signup,
  ChangePassword,
  Dashboard,
  Withdraw,
  RankAchievementReport,
  Support,
  EditProfile,
  PackageJoiningReport,
  TeamViewReport,
  RoiBonus,
  LevelBonus,
  RecommendationBonus,
  Profile,
  AllTransactions,
  WithdrawReport,
  DailyBusinessReport,
  ReferalUserList,
  RankBonus,
  TeamLevelBonusReport,
  RankAchievement,
  StakeWithDApp,
  TreeView,
  LegBusiness,
} from "../pages";
import { RequiresAuth } from "../RequiresAuth";
import { MatchingLevelIncome } from "../pages/MatchingLevelIncome";
import { RewardIncomes } from "../pages/RewardIncomes";
import { PromotionRank } from "../pages/PromotionRank";
export const Routing = () => {

  return (
    <Routes>

      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/" element={<LandingPage />} />

      <Route
        path="/support"
        element={
          <RequiresAuth>
            <Support />
          </RequiresAuth>
        }
      />

      <Route
        path="/treeView"
        element={
          <RequiresAuth>
            <TreeView />
          </RequiresAuth>
        }
      />

      <Route
        path="/referalUserList"
        element={
          <RequiresAuth>
            <ReferalUserList />
          </RequiresAuth>
        }
      />

      <Route
        path="/rankBonus"
        element={
          <RequiresAuth>
            <RankBonus />
          </RequiresAuth>
        }
      />

      <Route
        path="/rankAchievement"
        element={
          <RequiresAuth>
            <RankAchievement />
          </RequiresAuth>
        }
      />

      <Route
        path="/withdrawalHistory"
        element={
          <RequiresAuth>
            <WithdrawReport />
          </RequiresAuth>
        }
      />

      <Route
        path="/dailyBusinessReport"
        element={
          <RequiresAuth>
            <DailyBusinessReport />
          </RequiresAuth>
        }
      />

      <Route
        path="/changepassword"
        element={
          <RequiresAuth>
            <ChangePassword />
          </RequiresAuth>
        }
      />
      <Route
        path="/allTransactions"
        element={
          <RequiresAuth>
            <AllTransactions />
          </RequiresAuth>
        }
      />

      <Route
        path="/myprofile"
        element={
          <RequiresAuth>
            <Profile />
          </RequiresAuth>
        }
      />

      <Route
        path="/legBusiness"
        element={
          <RequiresAuth>
            <LegBusiness />
          </RequiresAuth>
        }
      />

      <Route
        path="/dashboard"
        element={
          <RequiresAuth>
            <Dashboard />
          </RequiresAuth>
        }
      />

      <Route
        path="/StakeWithDApp"
        element={
          <RequiresAuth>
            <StakeWithDApp />
          </RequiresAuth>
        }
      />
      <Route
        path="/packageJoiningReport"
        element={
          <RequiresAuth>
            <PackageJoiningReport />
          </RequiresAuth>
        }
      />

      <Route
        path="/withdraw"
        element={
          <RequiresAuth>
            <Withdraw />
          </RequiresAuth>
        }
      />
      <Route
        path="/rankAcheivementReport"
        element={
          <RequiresAuth>
            <RankAchievementReport />
          </RequiresAuth>
        }
      />

      <Route
        path="/editProfile"
        element={
          <RequiresAuth>
            <EditProfile />
          </RequiresAuth>
        }
      />

      <Route
        path="/teamViewReport"
        element={
          <RequiresAuth>
            <TeamViewReport />
          </RequiresAuth>
        }
      />
      <Route
        path="/staking_income"
        element={
          <RequiresAuth>
            <RoiBonus />
          </RequiresAuth>
        }
      />
      <Route
        path="/levelBonus"
        element={
          <RequiresAuth>
            <LevelBonus />
          </RequiresAuth>
        }
      />

      <Route
        path="/sponser_income"
        element={
          <RequiresAuth>
            <RecommendationBonus />
          </RequiresAuth>
        }
      />
      <Route
        path="/matching_income"
        element={
          <RequiresAuth>
            <TeamLevelBonusReport />
          </RequiresAuth>
        }
      />
      <Route
        path="/matching_level_income"
        element={
          <RequiresAuth>
            <MatchingLevelIncome />
          </RequiresAuth>
        }
      />

      <Route
        path="/reward_income"
        element={
          <RequiresAuth>
            <RewardIncomes />
          </RequiresAuth>
        }
      />

      <Route
        path="/promotion_rank"
        element={
          <RequiresAuth>
            <PromotionRank />
          </RequiresAuth>
        }
      />

    </Routes>
  );
};
