import React from "react";

export const Footer = () => {
  return (
    <>
      <footer
        className="position-absolute bottom-0 bg-dash-dark-2 text-white text-center py-3 w-100 text-xs"
        id="footer"
      >
        <div className="container-fluid text-center">
          <p className="mb-0 text-dash-gray">
            {/* © All Rights Reserved almightytoken.io 2023 */}
          </p>
          {/* <p className="mb-0">Designed & developed by <a style={{color:'#f34f79'}} href="https://appinop.com/" target="_blank">Appinop</a></p> */}
        </div>
      </footer>
    </>
  );
};
