import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export const LandingPage = () => {
  const [token, setToken] = useState();

  const navigate = useNavigate();

  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
    window.location.reload();
  };

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    console.log(token);
  }, [token]);

  return (
    <div className="page-wrapper">
      <header className="main-header position-relative header-style-six fixed-header navbar-expand-lg">
        {/* Header Upper  */}
        <div className="header-upper">
          <div className="container">
            <div className="nav navbar  align-items-center">
              <div className="logo">
                <Link to="/">
                  <img src="img/logo.png" alt="" title="" />
                </Link>
              </div>
              <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav align-items-lg-center mx-auto">
                        <li class="nav-item">
                            <a class="nav-link click-scroll" href="/">Home</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link click-scroll" href="/#about_us">About Us</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link click-scroll" href="/#Features">Features</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link click-scroll" target="_blank" href="img/almightywhitepaper.pdf">Whitepaper</a>
                        </li> 
                        <li class="nav-item">
                            <a class="nav-link click-scroll" href="/#footer">Contact us</a>
                        </li>
                    </ul>                                   
                </div>
              <div className="btn-box">
                {token ? (
                  <Link
                    to="/"
                    className="btn btn-primary btn-outline-primary border-gradient"
                  >
                    <span className="txt" onClick={() => logoutHandler()}>
                      Logout
                    </span>
                  </Link>
                ) : (
                  <Link
                    to="/login"
                    className="btn btn-primary btn-outline-primary border-gradient"
                  >
                    <span className="txt">Login</span>
                  </Link>
                )}

                {!token && (
                  <Link to="/signup" className=" btn btn-primary ms-3 ">
                    <span className="txt">Register</span>
                  </Link>
                )}

                    <button class="navbar-toggler ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button> 
              </div>
            </div>
          </div>
        </div>
        {/* End Header Upper */}
      </header>
      <section className="sec_1">
        <div className="container position-relative">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="banner-content mb-5 mb-sm-0 text-white">
                <h5 className="fw-normal"> Welcome to Almighty Token </h5>
                <h1 className="font-russo">
                Almighty Token brings to you a new opportunity.
                </h1>
                <p className="">
                  {" "}
                  We believe that the next frontier for fund management will be
                  transformation through artificial intelligence and machine
                  learning .{" "}
                </p>
                <Link to="/StakeWithDApp" className="btn btn-primary px-md-5">
                  start staking now
                </Link>
              </div>
            </div>
          </div>
        </div>
        <img src="img/home_img.jpg" className="img-fluid main_img" />
      </section>
      <section className="sec_2">
        <div className="container position-relative">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="sec-title text-center text-white">
                <h2 className="font-russo mb-4 ">
                  Years of struggle-get Insight into the world’s latest AI and
                  ML platform trade
                </h2>
                <p className="">
                  {" "}
                  Almighty Token is a global internet brokerage and investment
                  firm that focuses on laying strong foundations and cultivating
                  long-term connections. Our work reflects our demonstrable
                  skill as we work diligently to ensure that our customers'
                  expectations are addressed.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row g-4 justify-content-center">
            <div className="col-lg-4">
              <div className="my_card">
                <img src="img/ser_1.jpg" className="img-fluid" />
                <div className="card-body">
                  <h4>Decentralized finance</h4>
                  <p>
                    Cryptocurrency is used to simulate established financial
                    systems such as banks and exchanges.We have the ability to
                    carry out the kind of complicated functionality that
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="my_card">
                <img src="img/ser_2.jpg" className="img-fluid" />
                <div className="card-body">
                  <h4>Liquidity pool</h4>
                  <p>
                    Cryptocurrency is used to simulate established financial
                    systems such as banks and exchanges.We have the ability to
                    carry out the kind of complicated functionality that
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="my_card">
                <img src="img/ser_3.jpg" className="img-fluid" />
                <div className="card-body">
                  <h4>Smart investing</h4>
                  <p>
                    Cryptocurrency is used to simulate established financial
                    systems such as banks and exchanges.We have the ability to
                    carry out the kind of complicated functionality that
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec_3 about_sec"  id="about_us">
        <img src="img/about_img.png" className="about_img" />
        <div className="container position-relative">
          <div className="row justify-content-center gx-md-5">
            <div className="col-lg-6">
              <img src="img/about_img.svg" alt="" className="img-fluid ab_img" />
            </div>
            <div className="col-lg-6">
              <div className="sec-title text-start text-white mb-5">
                <h5 className="fw-normal text-gray"> ABOUT US </h5>
                <h2 className="font-russo mb-4 ">
                  Navigating your investments for higher growth with expertise
                  and technology together.
                </h2>
                <p className="">
                  {" "}
                  Our commitment to provide access to programmes and quality
                  assurances ensures that our customers receive the best
                  possible administration. Almighty Token has simplified and
                  secured online Forex trading.{" "}
                </p>
              </div>
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="my_card_about text-white">
                    <img src="img/about_1.png" className="img-fluid" />
                    <h4>Decentralized finance</h4>
                    <p>
                      Cryptocurrency is used to simulate established financial
                      systems such as banks and exchanges.We have the ability to
                      carry out the kind of complicated functionality that
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my_card_about  text-white">
                    <img src="img/about_2.png" className="img-fluid" />
                    <h4>Liquidity pool</h4>
                    <p>
                      Cryptocurrency is used to simulate established financial
                      systems such as banks and exchanges.We have the ability to
                      carry out the kind of complicated functionality that
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec_4 provide_sec">
        <img src="img/about_img2.png" className="about_img2" />
        <div className="container position-relative provide_container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="sec-title text-center text-white mb-5">
                <h5 className="fw-normal text-gray"> WHAT WE PROVIDES </h5>
                <h2 className="font-russo mb-4 ">
                  Where We can assist <br /> you with our expertise
                </h2>
              </div>
            </div>
          </div>

          <div className="row g-4 ">
            <div className="col-lg-4">
              <div className="my_card  text-white p-3 p-md-4">
                <img
                  src="img/ks_icon.svg"
                  className="img-fluid ms-auto d-block"
                />
                <div className="card-body p-2">
                  <h4>Knowledge sharing</h4>
                  <p>
                    Knowledge sharing is an activity in which people,
                    communities, or organisations exchange knowledge
                    (specifically, information, skills, or expertise)
                  </p>
                  <img
                    src="img/ks_img.svg"
                    className="mini_img img-fluid mt-3"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="my_card  text-white p-3 p-md-4">
                <img
                  src="img/cs_icon.svg"
                  className="img-fluid ms-auto d-block"
                />
                <div className="card-body p-2">
                  <h4>Custom solutions</h4>
                  <p>
                    We can custom-craft a solution to test the applicability of
                    your company idea, construct a prototype, and design a
                    blockchain that meets your criteria.
                  </p>
                  <img
                    src="img/cs_img.svg"
                    className="mini_img img-fluid mt-3"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="my_card  text-white p-3 p-md-4">
                <img
                  src="img/at_icon.svg"
                  className="img-fluid ms-auto d-block"
                />
                <div className="card-body p-2">
                  <h4>Algorithmic trading</h4>
                  <p>
                    We at Almighty Token understand that this method can be very
                    useful in determining the underlying value of securities and
                    is commonly used by professional validator operators in the
                    network and help secure the Interchain.
                  </p>
                  <img
                    src="img/at_img.svg"
                    className="mini_img img-fluid mt-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec_4 ctd_sec" id="Features">
        <img src="img/ctd_img.svg" className="ctd_img img-fluid" />
        <div className="container position-relative ctd_container">
          <div className="row justify-content-start">
            <div className="col-lg-7">
              <div className="sec-title text-start text-white mb-5">
                <h5 className="fw-normal text-gray">
                  {" "}
                  Almighty Token DEVELOPMENT{" "}
                </h5>
                <h2 className="font-russo mb-4 ">
                  How Almighty Token will <br /> change the future
                </h2>
                <p>
                  We believe in empowering everyone to build a better financial
                  future for themselves, and we believe that it begins with our
                  employees.
                </p>
              </div>
              {/* <img src="img/ctd_main.png" className="ctd_main img-fluid" /> */}
            </div>
            <div className="col-lg-5">
              <img src="img/ctd_chart.png" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <section className="sec_4 faq_sec">
        <div className="container position-relative ctd_container">
          <div className="row justify-content-start">
            <div className="col-lg-6">
              <div className="sec-title text-start text-white mb-5">
                <h5 className="fw-normal text-gray"> Need Help? </h5>
                <h2 className="font-russo mb-4 ">
                  You Can Learn More From <br /> Our Asked Questions
                </h2>
              </div>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      How can I invest in the fund?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      All interested investors that meet qualification criteria
                      can reach out to us directly at ( email id)
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Can I contribute in-kind?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      All interested investors that meet qualification criteria
                      can reach out to us directly at ( email id)
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      How can I invest in the fund?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      All interested investors that meet qualification criteria
                      can reach out to us directly at ( email id)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src="img/faq_img.png" className="faq_img img-fluid" />
      </section>

      <footer className="home_footer" id="footer">
        <img src="img/footer_img.png" className="img-fluid footer_img" />
        <div className="container container_footer">
          <div className="row gx-5">
            <div className="col-lg-8   col-md-8">
              <div className="footer_iteam">
                <img src="img/logo.png" />
                <p className="my-4">
                  Gain insight into our history, philosophy, people, and unique
                  approach to investment management using artificial
                  intelligence and machine learning where we’ve helped millions
                  of investors worldwide to pursue their real-life goals.
                </p>
              </div>
            </div>
            {/* <div className="col-lg-3  col-md-6 ">
              <div className="footer_iteam  text-white">
                <h5>Online News</h5>
                <div className="social_icons">
                  <a href="#" className="">
                    <span className="btn btn-icon">
                      <i className="ri-reddit-line"></i>
                    </span>
                  </a>
                  <a href="#" className="">
                    <span className="btn btn-icon">
                      <i className="ri-medium-line"></i>
                    </span>
                  </a>
                  <a href="#" className="">
                    <span className="btn btn-icon">
                      <i className="ri-bit-coin-line"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div> */}
            <div className="col-lg-4 col-md-4 ">
              <div className="footer_iteam  text-white">
                <h5 className="mb-3" >Contact us</h5>
                <div className="social_icons">
                  <a
                    href="mailto:almightytoken@gmail.com"
                    className="d-flex align-items-center"
                  >
                    <span className="btn btn-icon">
                      <i className="ri-mail-line"></i>{" "}
                    </span>{" "}
                     Email Us 
                  </a>
                </div>
                <hr />
                <h5 className="mb-0" >Follow us on:</h5>
                <div className="social_icons justify-content-start d-flex mt-3 mb-4">
                  
                  <a href="#" className="">
                    <span className="btn btn-icon">
                      <i className="ri-telegram-line"></i>
                    </span>
                  </a>
                  <a
                    href="#"
                    className=""
                  >
                    <span className="btn btn-icon me-0">
                      <i className="ri-instagram-line"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="container container_copyright">
            <div className="row align-items-center justify-content-center text-center">
              <div className="col-lg-6">
                <p className="mb-0">
                  {/* © All Rights Reserved almightytoken.com 2023 */}
                </p>
                {/* <p className="mb-0">
                  Designed & developed by{" "}
                  <a
                    style={{ color: "#f34f79" }}
                    href="https://appinop.com/"
                    target="_blank"
                  >
                    Appinop
                  </a>{" "}
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
