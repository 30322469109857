import { Link, useNavigate } from "react-router-dom";
import React from "react";
import { useData } from "../../context";

export const Header = () => {
  const navigate = useNavigate();
  const { handleToggle } = useData();
  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
    navigate("/");
    window.location.reload();
  };

  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand-lg py-3 bg-dash-dark-2 border-bottom border-dash-dark-1 z-index-10">
          <div className="container-fluid d-flex align-items-center justify-content-between py-1">
            <div className="navbar-header d-flex align-items-center">
              <Link to="#" className="navbar-brand text-uppercase text-reset">
                <div className="brand-text brand-big">
                  <img src="img/logo.png" alt="profile" />
                </div>
                <div className="brand-text brand-sm">
                  <img src="img/logo.png" alt="profile" />
                </div>
              </Link>
              <button
                className="sidebar-toggle"
                onClick={() => {
                  handleToggle();
                }}
              >
                <span className="svg-icon svg-icon-sm svg-icon-heavy transform-none">
                  <i className="fas fa-arrow-left"></i>
                </span>
              </button>
            </div>
            <ul className="list-inline mb-0">
              {/* Messages dropdown */}
              {/* <li className="list-inline-item dropdown px-lg-2">
                <Link
                  to="/"
                  className="nav-link text-reset px-1 px-lg-0"
                  id="navbarDropdownMenuLink1"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="svg-icon svg-icon-xs svg-icon-heavy">
                    <i className="fas fa-envelope"></i>
                  </span>
                  <span className="badge bg-dash-color-1">5</span>
                </Link>
                <ul
                  className="dropdown-menu dropdown-menu-end dropdown-menu-dark"
                  aria-labelledby="navbarDropdownMenuLink1"
                >
                  <li>
                    <a
                      className="dropdown-item d-flex align-items-center"
                      href="#"
                    >
                      <div className="position-relative">
                        <img
                          className="avatar avatar-md"
                          src="img/avatar-5.jpg"
                          alt="Sara Wood"
                        />
                        <div className="availability-status bg-secondary"></div>
                      </div>
                      <div className="ms-3">
                        {" "}
                        <strong className="d-block">Nadia Halsey</strong>
                        <span className="d-block text-xs">
                          lorem ipsum dolor sit amit
                        </span>
                        <small className="d-block">9:30am</small>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item text-center message" href="#">
                      <strong>
                        See All Messages{" "}
                        <i className="fas fa-angle-right ms-1"></i>
                      </strong>
                    </a>
                  </li>
                </ul>
              </li> */}
              <li className="list-inline-item logout px-lg-2">
                <Link
                  to="/"
                  onClick={logoutHandler}
                  className="nav-link text-sm text-reset px-1 px-lg-0"
                  id="logout"
                >
                  <span className="d-none d-sm-inline-block me-2">Logout</span>
                  <span className="svg-icon svg-icon-xs svg-icon-heavy">
                    <i className="fas fa-lock"></i>
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};
