import { Header, Footer, Sidebar } from "../../components";
import React, { useEffect, useState } from "react";
import AuthService from "../../api/services/AuthService";
import { Link } from "react-router-dom";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../../components/CustomAlertMessage";
import LoaderHelper from "../../components/Loading/LoaderHelper";
import BootstrapTable from "react-bootstrap-table-next";
// import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { CSVLink } from "react-csv";
import moment from "moment";

export const TeamViewReport = () => {
  //const [totalData, setTotalData] = useState([]);
  const [isShow, setIsShow] = useState(false);

  const { SearchBar } = Search;
  const [data, setData] = useState([]);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const dateFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <>{moment(row?.createdAt).format("DD MMM YYYY")}</>;
  };

  const statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <>{row?.stackingStatus}</>;
  };

  // const rankFormatter = (cell, row, rowIndex, formatExtraData) => {
  //   return <>{row?.current_rank}</>;
  // };

  const usernameFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <>{row?.username}</>;
  };

  // const levelFormatter = (cell, row, rowIndex, formatExtraData) => {
  //   return <>{row?.level}</>;
  // };

  const columns = [
    {
      dataField: "sl.no",
      text: "SR No.",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1;
      },
      sort: true,
    },
    // { dataField: "createdAt", text: "Date", formatter: dateFormatter },
    { dataField: "username", text: "Username", formatter: usernameFormatter },
    { dataField: "sponsercode", text: "Sponser Id" },
    { dataField: "level", text: "Level" },
    { dataField: "current_rank", text: "Rank" },
    { dataField: "stackingStatus", text: "Status", formatter: statusFormatter },
    {
      dataField: "activationAmount",
      text: "Activated with($)",
    },
    { dataField: "createdAt", text: "Joining Date", formatter: dateFormatter },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  useEffect(() => {
    handleLevelTeamMember();
  }, []);

  const handleLevelTeamMember = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.levelTeamMember().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setData(result.data);
          console.log(data);
          // alertSuccessMessage(result.message);
          setIsShow(true);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  console.log(data);

  return (
    <>
          <div className="wrapper">
        <Header />
        <div className="d-flex align-items-stretch">
          {/* Sidebar Navigation */}
          <Sidebar />
          <div className="page-content">
            {/* Page Header */}
            <div className="bg-dash-dark-2 py-4">
              <div className="container-fluid">
                <h2 className="h5 mb-0">Team View Report</h2>{" "}
              </div>
            </div>
            <div className="container-fluid py-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb py-3 px-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Team Member
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Team View Report
                  </li>
                </ol>
              </nav>
            </div>
            <section className="pt-0">
              <div className="container-fluid">
                <div className="row gy-4">
                  {/* Basic Form */}
                  {data?.length > 0 ? (
                    <div className="col-lg-12 m-auto">
                      <div className="card mb-0 h-100">
                        <div className="card-header pb-0 ">
                          {/* <div className=" row align-items-center mb-2"> */}
                          {/* <div className="col-md-7 col-lg-8"> */}
                          {/* <h3 className="h4 mb-0">Grand Total :- $0</h3> */}
                          {/* </div> */}
                          {/* <div className="col-md-5 col-lg-4"> */}
                          {/* <form className="">
                                <div className="input-group">
                                  <input
                                    type="search"
                                    className="form-control"
                                    placeholder=""
                                  />
                                  <button
                                    type="submit"
                                    className="btn btn-primary "
                                  >
                                    Search{" "}
                                  </button>
                                </div>
                              </form> */}
                          {/* </div> */}
                          {/* </div> */}
                        </div>
                        {/* <hr /> */}
                        <div className="card-body pt-0 py-0">
                          <div className="table-responsive">
                            <ToolkitProvider
                              hover
                              bootstrap4
                              keyField="_id"
                              columns={columns}
                              data={data}
                              exportCSV
                              search
                            >
                              {(props) => (
                                <React.Fragment>
                                  <SearchBar {...props.searchProps} />
                                  <BootstrapTable
                                    hover
                                    bootstrap4
                                    keyField="_id"
                                    columns={columns}
                                    data={data}
                                    pagination={pagination}
                                    filter={filterFactory()}
                                    {...props.baseProps}
                                  />
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <h5 className="text-center">No Data Available</h5>
                  )}
                </div>
              </div>
            </section>
            {/* Page Footer */}
            <Footer />
          </div>
          </div>
          </div>

    </>
  );
};
