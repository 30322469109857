import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { useState } from "react";
import AuthService from "../../api/services/AuthService";
import LoaderHelper from "../../components/Loading/LoaderHelper";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../../components/CustomAlertMessage";
import { useData } from "../../context";
import { $ } from "react-jquery-plugin";

export const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userName, setUserName, setName, setprofilePic, setLoginId, setRank } =
    useData();
  const [isAuth, setIsAuth] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [googleCode, setGoogleCode] = useState("");

  //console.log(isAuth, "isAuth");

  // const getProfileData = async () => {
  //   await AuthService.getuserProfile().then((result) => {
  //     if (result?.message === "User detail fetched Successfully!!") {
  //       try {
  //         setName(result?.data?.fullName);
  //         setprofilePic(result?.data?.profilepicture);
  //       } catch {}
  //     } else {
  //     }
  //   });
  // };

  const loginHandler = async (userName, password) => {
    await AuthService.login(userName, password).then(async (result) => {
      console.log(result);
      if (result.success) {
        if (!result.data.otp) {
          try {
            LoaderHelper.loaderStatus(false);
            alertSuccessMessage(result.message);
            setLoginId(result.data.loginid);
            setRank(result.data.current_rank);
            localStorage.setItem("token", result?.data?.token);
            localStorage.setItem("userId", result?.data?.user_id)
            let from = location.state?.from?.pathname || "/dashboard";
            navigate(from, { replace: true });
            // window.history.replaceState(<Dashboard />, "Page", "/dashboard");
            // getProfileData();
          } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
          }
        } else {
          $("#twofaModal").modal("show");
          setIsAuth(true);
          LoaderHelper.loaderStatus(false);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const forgotPassword = async (userName) => {
    if(userName == ""){
      alertErrorMessage("Username is required")
    } else{
      await AuthService.forgotPassword(userName).then(async (result) => {
        console.log(result);
        if (result.success) {
          try {
            LoaderHelper.loaderStatus(false);
            alertSuccessMessage(result.message);
          } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
          }
        } else {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(result.message)
        }
      });
    }
   
  };

  // const handlegoogleAuth = async (userName, password) => {
  //   await AuthService.googleAuthLogin(userName, password).then(
  //     async (result) => {
  //       if (result?.insdata?.message === "Login successful") {
  //         try {
  //           alertSuccessMessage(result?.insdata?.message);
  //           $("#twofaModal").modal("hide");
  //           localStorage.setItem("token", result?.insdata?.data?.token);
  //           navigate("/dashboard");
  //         } catch (error) {
  //           alertErrorMessage(error);
  //           console.log("error", `${error}`);
  //         }
  //       } else {
  //         alertErrorMessage(result?.message);
  //       }
  //     }
  //   );
  // };

  const togglePassword = () => {
    if (passwordType === "password") {
      return setPasswordType("text");
    }
    return setPasswordType("password");
  };

  return (
    <div className="login-page">
      <div className="container d-flex align-items-center position-relative py-5">
        <div className="card w-100 rounded overflow-hidden bg-none">
          <div className="card-body p-0">
            <div className="row gx-0 align-items-stretch">
              {/* Logo & Information Panel */}
              <Link to="/" className="col-12 text-center mb-5">
                <img className="login_logo" src="img/logo.png" />
              </Link>
              {/* Form Panel */}
              <div className="col-lg-6 m-auto">
                <div className="login_card p-md-5 p-3 bg-dash-dark-2">
                  <div className="login-title text-center text-white">
                    <h2>Welcome Back!</h2>
                    <p>Enter details to login Almighty Token.</p>
                  </div>

                  <form
                    className="login-form py-4 w-100"
                    method="get"
                    // onSubmit={loginHandler}
                  >
                    <div className="input-material-group mb-4">
                      <input
                        className="input-material"
                        id="login-username"
                        type="text"
                        name="loginUsername"
                        required
                        //placeholder="User Name"
                        data-validate-field="loginUsername"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                      <label className="label-material" for="login-username">
                        Enter User Name or EmailId
                      </label>
                    </div>
                    <div className="input-material-group mb-5 opt_btn_group">
                      <input
                        className="input-material"
                        id="login-password"
                        type={passwordType}
                        name="loginPassword"
                        autoComplete="off"
                        // placeholder="Password"
                        required
                        data-validate-field="loginPassword"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <a
                        className="show_password opt_btn btn-sm"
                        onClick={togglePassword}
                      >
                        {passwordType === "password" ? (
                          <i className="ri-eye-off-line" />
                        ) : (
                          <i className="ri-eye-line" />
                        )}
                      </a>
                      <label className="label-material" for="login-password">
                        Password
                      </label>
                    </div>

                    <Link
                      to="/login"
                      onClick={() => forgotPassword(userName)}
                      className="text-sm text-paleBlue"
                    >
                      Forgot Password?
                    </Link>

                    {/* <!-- <div className="d-flex align-items-center justify-content-between" > --> */}
                    <button
                      className="btn btn-primary mb-3 btn-block w-100 mt-4"
                      id="login"
                      type="button"
                      onClick={() => loginHandler(userName, password)}
                    >
                      Login
                    </button>
                    <br />
                    <Link
                      to="/signup"
                      className="btn btn btn-primary btn-outline-primary border-gradient px-5 btn-block w-100 mt-3"
                      id="login"
                    >
                      Back to Signup
                    </Link>
                    {/* <!-- </div> --> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        class="modal fade"
        id="twofaModal"
        tabindex="-1"
        aria-labelledby="twofaModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="twofaModalLabel">
                Verify Two Factor Authentication
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-md-5">
              <div class="mb-3">
                <label class="form-label" for="2FA">
                  Enter 2FA Code
                </label>
                <input
                  class="form-control"
                  id="2FA"
                  type="text"
                  aria-describedby="memberId"
                  name="memberId"
                  placeholder="Enter Code here.."
                  value={googleCode}
                  onChange={(e) => setGoogleCode(e.target.value)}
                />
              </div>
              <button
                className="btn btn-primary btn-block w-100 mt-3"
                type="button"
                onClick={() => handlegoogleAuth(userName, googleCode)}
              >
                Verify 2FA
              </button>
            </div>
          </div> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};
