const appUrl = "https://api.almightycrypto.info";

export const ApiConfig = {
  // =========EndPoints==========
  register: "register",
  getOtp: "send-otp",
  login: "login",
  forgotpassword: "forgot-password",
  changePassword: "change-password",
  changeTPassword: "auth/ChangeTransPass",
  editprofile: "edit-profile",
  getuserProfile: "get-details",
  googleAuth: "auth/getgAuthQr",
  update2fa: "auth/update2fa",
  walletTopup: "wallet/walletTopup",
  directTeamMember: "auth/getDirectRefUsers",
  levelTeamMember: "v1/referral/getlevelcounting",
  lifeTimeReward: "auth/lifeTimeReward",
  getRefCode: "v1/referral/get-referral-code",
  verifyotp: "auth/verifyotp",
  notification: "admin/NotificationList",
  transactionDetails: "getTransactionDetails",
  packageData: "v1/staking/getUserstaking",
  totalInvestment: "auth/totalstackingcount",
  currentBalance: "current_balance",
  totalBusiness: "totalBusiness",
  totalIncome: "totalIncome",
  myEarning: "auth/myEarning",
  todayBusiness: "auth/todayBusiness",
  todayEarning: "todayEarning",
  activationDate: "auth/activationDate",
  dailyCapping: "wallet/userBalance",
  withdrawalHistory: "withdrawal_histroy",
  walletWithdrawal: "withdrawal",
  directRefCount: "v1/referral/get-users-direct-referral",
  recommendationBonus: "recommendation_bonus",
  dailyIncome: "v1/stacking/getincomebytype",
  levelIncome: "levelIncome",
  selectStakingPackage: "v1/staking/selectStakingPackage",
  totalWithdrawal: "withdrawal_amount",
  businessReport: "dailyBusinessReport",
  legBusinessReport: "wallet/rank_calculation",
  rankBonus: "wallet/rankBonus",
  checkUserAddress: "auth/checkusersaddress",
  teamLevelBonusReport: "teamLevelBonusReport",
  getSupport: "sendSupport",
  getUsername: "auth/sponsercode",
  verifyWalletAddress: "auth/verifyWalletAddress",
  levelUserList: "v1/referral/levelOneUserList",
  allDirectUsers: "auth/getDirectRefUsersAll",

  // ============URLs================
  baseUrl: `${appUrl}/`,
  baseUser: `${appUrl}/v1/user/`,
  baseWallet: `${appUrl}/v1/wallet/`,
  appUrl: `${appUrl}/`,
};
