import { Header, Sidebar, Footer } from "../../components";
import AuthService from "../../api/services/AuthService";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../../components/CustomAlertMessage";
import { useData } from "../../context";
import { Link } from "react-router-dom";
import LoaderHelper from "../../components/Loading/LoaderHelper";
import React, { useState, useEffect } from "react";

export const Withdraw = () => {
  const [walletAddress, setWalletAddress] = useState();
  const [walletAmount, setWalletAmount] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const [currentBalance, setCurrentBalance] = useState();

  useEffect(() => {
    getCurrentBalance();
    getProfileData();
  });

  const submitHandler = async (walletAddress, walletAmount) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getWithdrawal(walletAddress, walletAmount).then(
      async (result) => {
        console.log(result);
        LoaderHelper.loaderStatus(false);
        if (result.success) {
          try {
            alertSuccessMessage(result.message);
            setIsShow(true);
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(result.message);
        }
      }
    );
  };

  const getCurrentBalance = async () => {
    await AuthService.getCurrentBalance().then(async (result) => {
      console.log(result);
      if (result.success) {
        try {
          setCurrentBalance(result?.data?.usdt_balance);
        } catch {
          console.log(result.message);
        }
      } else {
        console.log(result.message);
      }
    });
  };

  const getProfileData = async () => {
    await AuthService.getuserProfile().then((result) => {
      if (result.success) {
        try {
          setWalletAddress(result?.data.wallet_address);
        } catch {
          console.log(result.message);
        }
      } else {
      }
    });
  };

  const clearHandler = () => {
    setWalletAddress("");
    setWalletAmount("");
  };

  ///////
  function debounce(func, timeout = 1000) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  // function handleWalletAmount(input) {
  //   if (input.length > 1) {
  //     setWalletAmount(Math.round(input / 10) * 10);
  //   } else {
  //     setWalletAmount(input);
  //   }
  // }

  // const processChanges = debounce((input) => handleWalletAmount(input));
  let timerId = null;
  function validateInput(input) {
    // setdisableBtn(true)
    // setWalletAmount(input)
    setWalletAmount(Math.round(input / 10) * 10);
    // clearTimeout(timerId);
    // if (input>1) {
    //   timerId = setTimeout(() => {
    //     setdisableBtn(false)
    //   }, 2000);
    // } else{
    //   timerId = setTimeout(() => {
    //     setdisableBtn(false)
    //     setWalletAmount(0);
    //   }, 100);
    // }
  }

  return (
    <>
      <div className="wrapper">
        <Header />
        <div className="d-flex align-items-stretch">
          {/* Sidebar Navigation */}
          <Sidebar />
          <div className="page-content">
            {/* Page Header */}
            <div className="bg-dash-dark-2 py-4">
              <div className="container-fluid">
                <h2 className="h5 mb-0">Withdraw</h2>{" "}
              </div>
            </div>
            <div className="container-fluid py-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb py-3 px-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Withdraw
                  </li>
                </ol>
              </nav>
            </div>
            <section className="pt-0">
              <div className="container-fluid" />
              <div className="row gy-4">
                {/* Basic Form */}
                <div className="col-lg-6 m-auto">
                  <div className="card  h-100">
                    <div className="card-header">
                      <div className="card-body pt-0">
                        <div>Available Balance : ${currentBalance}</div>
                        <form
                        // onSubmit={(e) => {
                        //   {
                        //     e.preventDefault();
                        //     submitHandler(walletAddress, walletAmount);
                        //   }
                        // }}
                        >
                          {/* <div className="mb-3">
                            <label
                              className="form-label"
                              for="exampleInputMember"
                            >
                              Wallet Type
                            </label>
                            <input
                              className="form-control"
                              id="exampleInputMember"
                              value="Main Wallet"
                            />
                          </div> */}

                          <div className="mb-3">
                            <label
                              className="form-label"
                              for="exampleInputMember"
                            >
                              Wallet Address
                            </label>
                            <input
                              className="form-control"
                              id="exampleInputMember"
                              value={walletAddress}
                              readonly
                            />
                          </div>
                          <div className="mb-3">
                            <div className="form-group">
                              <label
                                className="form-label"
                                for="exampleInputOTP"
                              >
                                Amount($)
                              </label>
                              <div className="input-group">
                                <input
                                  className="form-control"
                                  id="exampleInputOTP"
                                  type="number"
                                  aria-describedby="OTP"
                                  // value={walletAmount}
                                  onChange={(e) => {
                                    validateInput(e.target.value);
                                  }}
                                />

                                <button
                                  disabled
                                  className="btn btn-primary btn-md"
                                  type="button"
                                  style={{
                                    color: "black",
                                    background: "white",
                                  }}
                                >
                                  Amount: ${walletAmount}
                                </button>
                              </div>
                            </div>
                            <span style={{ color: "red" }}>
                              Amount should be in multiple of 10
                            </span>
                          </div>
                          <button
                            className="btn btn-primary px-5 "
                            type="button"
                            onClick={() => {
                              {
                                submitHandler(walletAddress, walletAmount);
                                clearHandler();
                              }
                            }}
                          >
                            Submit
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Page Footer */}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
