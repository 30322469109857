import React, { createContext, useContext, useEffect, useState } from "react";
import AuthService from "../api/services/AuthService";

const DataContext = createContext();

const DataProvider = ({ children }) => {
  // const [password, setPassword] = useState("");
  const [loginId, setLoginId] = useState("");
  const [name, setName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [tPassword, setTPassword] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [sponserId, setSponserId] = useState("");
  const [countryValue, setCountryValue] = useState("India");
  const [dailyCapping, setDailyCapping] = useState();
  // const [profilePic, setProfilePic] = useState([]);
  const [walletAddress, setWalletAddress] = useState("");

  const [senderAddress, setSenderAddress] = useState("");
  const [toggle, setToggle] = useState(false);
  const [rank, setRank] = useState("");

  useEffect(() => {
    getProfileData();
  }, []);

  const handleToggle = () => {
    setToggle(!toggle);
  };
  const getProfileData = async () => {
    await AuthService.getuserProfile().then((result) => {
      if (result?.success) {
        try {
          setName(result?.data?.fullName);
          setMobileNo(result?.data?.mobileNumber);
          setEmail(result?.data?.email);
          setUserName(result?.data?.username);
          // setProfilePic(result?.data?.profilepicture);
          setLoginId(result?.data?.loginid);
          setWalletAddress(result?.data?.wallet_address);
        } catch {}
      } else {
      }
    });
  };

  // console.log(profilePic);

  return (
    <DataContext.Provider
      value={{
        handleToggle,
        toggle,
        walletAddress,
        // password,
        // setPassword,
        userName,
        setUserName,
        tPassword,
        setTPassword,
        email,
        setEmail,
        name,
        setName,
        mobileNo,
        setMobileNo,
        loginId,
        setLoginId,
        sponserId,
        setSponserId,
        countryValue,
        setCountryValue,
        // walletAddress,
        // setWalletAddress,
        senderAddress,
        setSenderAddress,
        dailyCapping,
        setDailyCapping,
        // profilePic,
        // setProfilePic,

        rank,
        setRank,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

const useData = () => useContext(DataContext);

export { DataProvider, useData };
