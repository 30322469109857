import { Header, Footer, Sidebar } from "../../components";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AuthService from "../../api/services/AuthService";
import LoaderHelper from "../../components/Loading/LoaderHelper";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../../components/CustomAlertMessage";
import BootstrapTable from "react-bootstrap-table-next";
// import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import moment from "moment";
import { CSVLink } from "react-csv";

export const RewardIncomes = () => {

  const { SearchBar } = Search;
  const [isShow, setIsShow] = useState(false);
  const [finalData, setFinalData] = useState([]);

  const dateFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <>{moment(row?.createdAt).format("DD MMM YYYY")}</>;
  };

  const columns = [
    {
      dataField: "sl.no",
      text: "SR No.",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1;
      },
      sort: true,
    },
    { dataField: "username", text: "Username" },
    { dataField: "level", text: "Level" },
    { dataField: "amount", text: "Total Amount($)" },
    { dataField: "createdAt", text: "Date", formatter: dateFormatter },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  useEffect(() => {
    getTeamLevelBonusReport();
  }, []);

  const getTeamLevelBonusReport = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getTeamLevelBonusReport().then(async (result) => {
      console.log(result);
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setFinalData(result?.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };


  return (
    <div className="wrapper">
      <Header />
      <div className="d-flex align-items-stretch">
        <Sidebar />
        <div className="page-content">
          <div className="bg-dash-dark-2 py-4">
            <div className="container-fluid">
              <h2 className="h5 mb-0">Reward Income</h2>{" "}
            </div>
          </div>
          <div className="container-fluid py-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb py-3 px-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Income
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Reward Income
                </li>
              </ol>
            </nav>
          </div>
          <section className="pt-0">
            <div className="container-fluid">
              <div className="row gy-4">
                {finalData?.length > 0 || null ? (
                  <div className="col-lg-12 m-auto">
                    <div className="card mb-0 h-100">
                      <div className="card-body pt-0 py-0">
                        <div className="table-responsive">
                          <ToolkitProvider
                            hover
                            bootstrap4
                            keyField="_id"
                            columns={columns}
                            data={finalData}
                            exportCSV
                            search
                          >
                            {(props) => (
                              <React.Fragment>
                                <SearchBar {...props.searchProps} />
                                <BootstrapTable
                                  hover
                                  bootstrap4
                                  keyField="_id"
                                  columns={columns}
                                  data={finalData}
                                  pagination={pagination}
                                  filter={filterFactory()}
                                  {...props.baseProps}
                                />
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <h5 className="text-center">No Data Available</h5>
                )}
              </div>
            </div>
          </section>
          {/* Page Footer */}
          <Footer />
        </div>
      </div>
    </div>
  );
};
