import { ApiConfig } from "../apiConfig/apiConfig";
import { ApiCallPost, ApiCallGET } from "../apiConfig/apiCall";
import { ConsoleLogs } from "../../utils/ConsoleLogs";

const TAG = "AuthService";

const AuthService = {
  register: async (
    sponserId,
    userName,
    fullName,
    countryCode,
    countryValue,
    mobileNo,
    email,
    password,
    cPassword,
    otp
  ) => {
    const { baseUser, register } = ApiConfig;
    const url = baseUser + register;
    const params = {
      referral_code: sponserId,
      username: userName,
      fullName: fullName,
      country_code: countryCode,
      country: countryValue,
      mobileNumber: mobileNo,
      email: email,
      password: password,
      confirm_password: cPassword,
      otp: +otp,
    };
    ConsoleLogs(TAG + ", register", `url : ' + ${url}`);
    ConsoleLogs(
      TAG + ", register",
      `loginRequestParams : ' + ${JSON.stringify(params)}`
    );
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getOtp: async (email) => {
    console.log(email);
    const { baseUser, getOtp } = ApiConfig;
    const url = baseUser + getOtp;

    const params = {
      email: email,
      type:"registration"
    };

    ConsoleLogs(TAG + ", getOtp", `url : ' + ${url}`);
    ConsoleLogs(
      TAG + ", getOtp",
      `loginRequestParams : ' + ${JSON.stringify(params)}`
    );

    const headers = {
      "Content-Type": "application/json",
    };

    return ApiCallPost(url, params, headers);
  },

  getChangePasswordOtp: async (email) => {
    const { baseUser, getOtp } = ApiConfig;
    const url = baseUser + getOtp;
    const params = {
      email: email,
    };

    ConsoleLogs(TAG + ", getOtp", `url : ' + ${url}`);
    ConsoleLogs(
      TAG + ", getOtp",
      `loginRequestParams : ' + ${JSON.stringify(params)}`
    );

    const headers = {
      "Content-Type": "application/json",
    };

    return ApiCallPost(url, params, headers);
  },

  login: async (userName, password) => {
    const { baseUser, login } = ApiConfig;
    const url = baseUser + login;
    const params = {
      username: userName,
      password: password,
    };
    ConsoleLogs(TAG + ", login", `url : ' + ${url}`);
    ConsoleLogs(
      TAG + ", login",
      `loginRequestParams : ' + ${JSON.stringify(params)}`
    );
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  forgotPassword: async (userName) => {
    const { baseUser, forgotpassword } = ApiConfig;

    const url = baseUser + forgotpassword;

    const params = {
      username: userName,
    };

    ConsoleLogs(TAG + ", forgotpassword", `url : ' + ${url}`);
    ConsoleLogs(
      TAG + ", forgotpassword",
      `loginRequestParams : ' + ${JSON.stringify(params)}`
    );

    const headers = {
      "Content-Type": "application/json",
    };

    return ApiCallPost(url, params, headers);
  },

  changePassword: async (otp, currentPass, newPass, confirmNewPass) => {
    const token = localStorage.getItem("token");
    const { baseUser, changePassword } = ApiConfig;
    const url = baseUser + changePassword;
    const params = {
      otp: +otp,
      current_password: currentPass,
      new_password: newPass,
      confirm_password: confirmNewPass,
    };

    ConsoleLogs(TAG + ", changePassword", `url : ' + ${url}`);

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  changeTPassword: async (otp, newTPassword, confirmNewTPassword) => {
    const token = localStorage.getItem("token");
    const { baseUrl, changeTPassword } = ApiConfig;

    const url = baseUrl + changeTPassword;

    const params = {
      otp: +otp,
      newTransPass: newTPassword,
      confirmTransPass: confirmNewTPassword,
    };

    ConsoleLogs(TAG + ", changeTPassword", `url : ' + ${url}`);

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },
  getuserProfile: async () => {
    const token = localStorage.getItem("token");
    const { baseUser, getuserProfile } = ApiConfig;
    const url = baseUser + getuserProfile;
    ConsoleLogs(TAG + ", getuserProfile", `url : ' + ${url}`);

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallGET(url, headers);
  },
  verifyWalletAddress: async (loginId, senderAddress) => {
    const token = localStorage.getItem("token");
    const { baseUrl, verifyWalletAddress } = ApiConfig;

    const url = baseUrl + verifyWalletAddress;

    const params = {
      loginid: loginId,
      wallet_address: senderAddress,
    };

    ConsoleLogs(TAG + ", getuserProfile", `url : ' + ${url}`);

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },
  editprofile: async (formData) => {
    const token = localStorage.getItem("token");
    const { baseUser, editprofile } = ApiConfig;

    const url = baseUser + editprofile;

    ConsoleLogs(TAG + ", editprofile", `url : ' + ${url}`);

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };

    return ApiCallPost(url, formData, headers);
  },

  googleAuth: async () => {
    const token = localStorage.getItem("token");
    console.log(token);
    const { baseUrl, googleAuth } = ApiConfig;

    const url = baseUrl + googleAuth;
    const params = {};

    ConsoleLogs(TAG + ", getNotifications", `url : ' + ${url}`);

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  update2fa: async (twoFactorStatus, password, vCode) => {
    const token = localStorage.getItem("token");
    const { baseUrl, update2fa } = ApiConfig;

    const url = baseUrl + update2fa;

    const params = {
      status: "1",
      password: password,
      code: vCode,
    };

    ConsoleLogs(TAG + ", update2fa", `url : ' + ${url}`);

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  googleDisable: async (password, vCode) => {
    const token = localStorage.getItem("token");
    const { baseUrl, update2fa } = ApiConfig;

    const url = baseUrl + update2fa;

    const params = {
      status: "0",
      password: password,
      code: vCode,
    };

    ConsoleLogs(TAG + ", update2fa", `url : ' + ${url}`);

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  walletTopup: async (topupAmount, txHash) => {
    const token = localStorage.getItem("token");
    console.log(token);
    const { baseUrl, walletTopup } = ApiConfig;

    const url = baseUrl + walletTopup;

    const params = {
      amount: topupAmount,
      txHash: txHash,
    };

    ConsoleLogs(TAG + ", walletTopup", `url : ' + ${url}`);

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  directTeamMember: async () => {
    const token = localStorage.getItem("token");
    const { baseUrl, directTeamMember } = ApiConfig;

    const url = baseUrl + directTeamMember;

    const params = {};

    ConsoleLogs(TAG + ", directTeamMember", `url : ' + ${url}`);

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  allDirectUsers: async () => {
    const token = localStorage.getItem("token");
    const { baseUrl, allDirectUsers } = ApiConfig;

    const url = baseUrl + allDirectUsers;

    const params = {};

    ConsoleLogs(TAG + ", directTeamMember", `url : ' + ${url}`);

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  levelTeamMember: async () => {
    const token = localStorage.getItem("token");
    const { baseUrl, levelTeamMember } = ApiConfig;
    const url = baseUrl + levelTeamMember;
    ConsoleLogs(TAG + ", levelTeamMember", `url : ' + ${url}`);

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallGET(url, headers);
  },

  lifeTimeReward: async () => {
    const token = localStorage.getItem("token");
    const { baseUrl, lifeTimeReward } = ApiConfig;

    const url = baseUrl + lifeTimeReward;

    const params = {};

    ConsoleLogs(TAG + ", lifeTimeReward", `url : ' + ${url}`);

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },
  getRefCode: async () => {
    const token = localStorage.getItem("token");

    const { baseUrl, getRefCode } = ApiConfig;
    const url = baseUrl + getRefCode;
    const params = {};
    ConsoleLogs("getRefCode", `url: + ${url}`);
    ConsoleLogs("getRefCode", `getRefCode : ' + ${JSON.stringify(params)}`);
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  googleAuthLogin: async (loginId, password) => {
    const { baseUrl, verifyotp } = ApiConfig;
    const url = baseUrl + verifyotp;
    const params = {
      username: loginId,
      code: password,
      type: "1",
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getNotification: async () => {
    const { baseUrl, notification } = ApiConfig;
    const url = baseUrl + notification;
    const params = {};
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getTransactionDetails: async () => {
    const token = localStorage.getItem("token");
    const { baseWallet, transactionDetails } = ApiConfig;
    const url = baseWallet + transactionDetails;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  handlePackageData: async () => {
    const token = localStorage.getItem("token");
    const { baseUrl, packageData } = ApiConfig;
    const url = baseUrl + packageData;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  getTotalInvestment: async () => {
    const token = localStorage.getItem("token");
    const { baseUrl, totalInvestment } = ApiConfig;
    const url = baseUrl + totalInvestment;
    const params = {};
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getCurrentBalance: async () => {
    const token = localStorage.getItem("token");
    const { baseWallet, currentBalance } = ApiConfig;
    const url = baseWallet + currentBalance;
    const params = {};
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url,headers);
  },

  getTotalBusiness: async () => {
    const token = localStorage.getItem("token");
    const { baseWallet, totalBusiness } = ApiConfig;
    const url = baseWallet + totalBusiness;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  getTotalIncome: async () => {
    const token = localStorage.getItem("token");
    const { baseWallet, totalIncome } = ApiConfig;
    const url = baseWallet + totalIncome;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  getMyEarning: async () => {
    const token = localStorage.getItem("token");
    const { baseUrl, myEarning } = ApiConfig;
    const url = baseUrl + myEarning;
    const params = {};
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getTodayBusiness: async () => {
    const token = localStorage.getItem("token");
    const { baseUrl, todayBusiness } = ApiConfig;
    const url = baseUrl + todayBusiness;
    const params = {};
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getTodayEarning: async () => {
    const token = localStorage.getItem("token");
    const { baseWallet, todayEarning } = ApiConfig;
    const url = baseWallet + todayEarning;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  getActivationDate: async () => {
    const token = localStorage.getItem("token");
    const { baseUrl, activationDate } = ApiConfig;
    const url = baseUrl + activationDate;
    const params = {};
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getDailyCapping: async () => {
    const token = localStorage.getItem("token");
    const { baseUrl, dailyCapping } = ApiConfig;
    const url = baseUrl + dailyCapping;
    const params = {};
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getDirectRefCount: async () => {
    const token = localStorage.getItem("token");
    const { baseUrl, directRefCount } = ApiConfig;
    const url = baseUrl + directRefCount;
    const params = {};
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  getWithdrawalHistory: async () => {
    const token = localStorage.getItem("token");
    const { baseWallet, withdrawalHistory } = ApiConfig;
    const url = baseWallet + withdrawalHistory;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  getWithdrawal: async (walletAddress, walletAmount) => {
    const token = localStorage.getItem("token");
    const { baseWallet, walletWithdrawal } = ApiConfig;
    const url = baseWallet + walletWithdrawal;
    const params = {
      // wallType: "Main Wallet",
      withdrawalAddress: walletAddress,
      amount: walletAmount,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getRecommendationBonus: async () => {
    const token = localStorage.getItem("token");
    const { baseWallet, recommendationBonus } = ApiConfig;
    const url = baseWallet + recommendationBonus;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  getDailyIncome: async (type) => {
    const token = localStorage.getItem("token");
    const { baseUrl, dailyIncome } = ApiConfig;
    const url = baseUrl + dailyIncome + `?type=${type}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  getLevelIncome: async () => {
    const token = localStorage.getItem("token");
    const { baseWallet, levelIncome } = ApiConfig;
    const url = baseWallet + levelIncome;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url,headers);
  },

  getTotalWithdrawal: async () => {
    const token = localStorage.getItem("token");
    const { baseWallet, totalWithdrawal } = ApiConfig;
    const url = baseWallet + totalWithdrawal;
    const params = {};
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url,headers);
  },

  getBusinessReport: async () => {
    const token = localStorage.getItem("token");
    const { baseUser, businessReport } = ApiConfig;
    const url = baseUser + businessReport;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  legBusinessReport: async () => {
    const token = localStorage.getItem("token");
    const { baseUrl, legBusinessReport } = ApiConfig;
    const url = baseUrl + legBusinessReport;
    const params = {};
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getRankBonus: async () => {
    const token = localStorage.getItem("token");
    const { baseUrl, rankBonus } = ApiConfig;
    const url = baseUrl + rankBonus;
    const params = {};
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getTeamLevelBonusReport: async () => {
    const token = localStorage.getItem("token");
    const { baseWallet, teamLevelBonusReport } = ApiConfig;
    const url = baseWallet + teamLevelBonusReport;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url,headers);
  },

  checkUserAddress: async (token_addr) => {
    const token = localStorage.getItem("token");
    const { baseUrl, checkUserAddress } = ApiConfig;
    const url = baseUrl + checkUserAddress;
    const params = {
      wallet_address: token_addr,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getUsername: async () => {
    const token = localStorage.getItem("token");
    const { baseUrl, getUsername } = ApiConfig;
    const url = baseUrl + getUsername;
    const params = {
      // code: sponserId,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getSupport: async (userName,
    userEmail,
    userContactNo,
    subject,
    description) => {
    const token = localStorage.getItem("token");
    const { baseUser, getSupport } = ApiConfig;
    const url = baseUser + getSupport;
    const params = {
    fullName: userName,
    mobileNumber: userContactNo,
    email : userEmail,
    subject : subject,
    description: description,
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  selectstackingpackage: async (
    USDTamount,
    packageType,
    transactionHash,
    senderAddress,
    depositAddress
  ) => {
    const token = localStorage.getItem("token");
    const { baseUrl, selectStakingPackage } = ApiConfig;
    const url = baseUrl + selectStakingPackage;
    const params = {
    total_amount: USDTamount * 2,
    package_name: packageType,
    transaction_hash: transactionHash,
    amount: +USDTamount,
    deposit_address: depositAddress,
    sender_address: senderAddress,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  levelUserList: async (loginid) => {
    const token = localStorage.getItem("token");
    const { baseUrl, levelUserList } = ApiConfig;
    const url = baseUrl + levelUserList;
    const params = {
      user_id: loginid,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },
};

export default AuthService;
