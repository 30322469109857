import React, { useEffect, useState } from "react";
import { useData } from "../../context";
import { Link } from "react-router-dom";
import { Footer, Sidebar, Header } from "../../components";
import AuthService from "../../api/services/AuthService";
import { ApiConfig } from "../../api/apiConfig/apiConfig";

export const Profile = () => {
  useEffect(() => {
    getProfileData();
  }, []);

  const {
    userName,
    setUserName,
    email,
    setEmail,
    name,
    setName,
    mobileNo,
    setMobileNo,
    loginId,
    setLoginId,
    countryValue,
    senderAddress,
  } = useData();

  const [walletAddress, setWalletAddress] = useState("");

  console.log(walletAddress);

  const [sponserId, setSponserId] = useState("");
  const [profilePic, setProfilePic] = useState([]);

  const getProfileData = async () => {
    await AuthService.getuserProfile().then((result) => {
      if (result.success) {
        try {
          setName(result?.data?.fullName);
          setMobileNo(result?.data?.mobileNumber);
          setEmail(result?.data?.email);
          setUserName(result?.data?.username);
          setProfilePic(result?.data?.profilepicture);
          // setLoginId(result?.data?.loginid);
          setWalletAddress(result?.data.wallet_address);
          setSponserId(result?.data?.referral_code);
        } catch {}
      } else {
      }
    });
  };

  console.log(profilePic, "fgfhyh");

  console.log(sponserId);

  return (
    <>
      <div className="wrapper">
        <Header />

        <div className="d-flex align-items-stretch">
          {/* Sidebar Navigation */}
          <Sidebar />
          <div className="page-content">
            {/* Page Header */}
            <div className="bg-dash-dark-2 py-4">
              <div className="container-fluid">
                <h2 className="h5 mb-0"> My Profile</h2>{" "}
              </div>
            </div>
            <div className="container-fluid py-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb py-3 px-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Profile
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    My Profile
                  </li>
                </ol>
              </nav>
            </div>
            <section className="pt-0">
              <div className="container-fluid">
                <div className="row gy-4">
                  {/* Basic Form */}
                  <div className="col-lg-6 m-auto">
                    <div className="card  h-100">
                      <div className="card-header">
                        {/* <h3 className="h4 mb-0">Member Information</h3>{" "} */}
                      </div>

                      <div className="card-body pt-0">
                        <form>
                          <div className="mb-3 d-flex">
                            {!profilePic ? (
                              <img
                                src="img/profilelogo.png"
                                alt="user avatar"
                              />
                            ) : (
                              <img
                                className="avatar shadow-0 img-fluid rounded-circle"
                                src={`${
                                  ApiConfig.baseUrl + profilePic
                                }`}
                                alt="user avatar"
                              />
                            )}
                          </div>
                          <div className="mb-3">
                            <label
                              className="form-label"
                              for="exampleInputName"
                            >
                              Name
                            </label>
                            <input
                              className="form-control"
                              id="exampleInputName"
                              type="text"
                              aria-describedby="Name"
                              value={name}
                            />
                          </div>

                          <div className="mb-3">
                            <label
                              className="form-label"
                              for="exampleInputName"
                            >
                              Username
                            </label>
                            <input
                              className="form-control"
                              id="exampleInputName"
                              type="text"
                              aria-describedby="Name"
                              value={userName}
                            />
                          </div>

                          <div className="mb-3">
                            <label
                              className="form-label"
                              for="exampleInputEmail1"
                            >
                              Email ID
                            </label>
                            <input
                              className="form-control"
                              id="exampleInputEmail1"
                              type="email"
                              aria-describedby="Email"
                              value={email}
                            />
                          </div>

                          <div className="mb-3">
                            <label
                              className="form-label"
                              for="exampleInputEmail1"
                            >
                              Country
                            </label>
                            <input
                              className="form-control"
                              id="exampleInputEmail1"
                              type="email"
                              aria-describedby="Email"
                              value={countryValue}
                            />
                          </div>

                          <div className="mb-3">
                            <label
                              className="form-label"
                              for="exampleInputEmail1"
                            >
                              Sponser ID
                            </label>
                            <input
                              className="form-control"
                              id="exampleInputEmail1"
                              // type="TEXT"
                              // aria-describedby="Email"
                              value={sponserId === "" ? "NA" : sponserId}
                            />
                          </div>

                          <div className="mb-3">
                            <label
                              className="form-label"
                              for="exampleInputMobile"
                            >
                              Mobile No
                            </label>
                            <input
                              className="form-control"
                              id="exampleInputMobile"
                              type="tel"
                              aria-describedby="Mobile"
                              value={mobileNo}
                              onChange={(e) => setMobileNo(e.target.value)}
                            />
                          </div>

                          <div className="mb-3">
                            <label
                              className="form-label"
                              for="exampleInputMobile"
                            >
                              Wallet Address
                            </label>
                            <input
                              className="form-control"
                              id="exampleInputMobile"
                              // type="tel"
                              aria-describedby="Mobile"
                              value={walletAddress}
                              // onChange={(e) => setMobileNo(e.target.value)}
                            />
                          </div>
                          {/* <div className="mb-3">
                            <label
                              className="form-label"
                              for="exampleInputCountry"
                            >
                              Country
                            </label>
                            <select
                              className="form-select"
                              id="inlineFormSelectPref"
                            >
                              <option selected="">Choose...</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div> */}
                          {/* <div className="mb-3">
                            <label
                              className="form-label"
                              for="exampleInputPassword1"
                            >
                              USDT Address{" "}
                            </label>
                            <input
                              className="form-control"
                              id="exampleInputPassword1"
                              type="text"
                              value="asASFASFASASFASFASAA23234"
                            />{" "}
                          </div> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Page Footer */}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
