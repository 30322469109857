import { Sidebar, Header, Footer } from "../../components";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useData } from "../../context";
import AuthService from "../../api/services/AuthService";
import CopyToClipboard from "react-copy-to-clipboard";
import { alertSuccessMessage, } from "../../components/CustomAlertMessage";

export const Dashboard = () => {
  const [RefCode, setRefCode] = useState();
  const { setSponserId, } = useData();
  const [directRefCount, setDirectRefCount] = useState();
  const [totalWithdrawal, setTotalWithdrawal] = useState();
  const [currentBalance, setCurrentBalance] = useState();
  const [totalIncome, setTotalIncome] = useState();
  const [todayEarning, setTodayEarning] = useState();


  const getRefCode = async () => {
    await AuthService.getRefCode().then(async (result) => {
      if (result.success) {
        setRefCode(result?.data?.user_code);
        setSponserId(result?.data?.sponser_id)
      }
    });
  };


  const getTotalWithdrawal = async () => {
    await AuthService.getTotalWithdrawal().then(async (result) => {
      if (result.success) {
        setTotalWithdrawal(result?.data?.totalWithdraw);
      }
    });
  };

  const getCurrentBalance = async () => {
    await AuthService.getCurrentBalance().then(async (result) => {
      if (result.success) {
        setCurrentBalance(result?.data?.usdt_balance);
      }
    });
  };

  const getTotalIncome = async () => {
    await AuthService.getTotalIncome().then(async (result) => {
      if (result.success) {
        setTotalIncome(result?.data.totalTrans.toFixed(2));
      }
    });
  };

  const getTodayEarning = async () => {
    await AuthService.getTodayEarning().then(async (result) => {
      if (result.success) {
        setTodayEarning(result?.data.total.toFixed(2));
      }
    });
  };

  const getDirectRefCount = async () => {
    await AuthService.getDirectRefCount().then(async (result) => {
      if (result.success) {
        if (result.data.length > 0) {
          setDirectRefCount(result?.data?.length);
        } else {
          setDirectRefCount(0);
        }
        setDirectRefCount(result?.data?.length);
      }
    });
  };

  useEffect(() => {
    getRefCode();
    getCurrentBalance();
    getTotalIncome();
    getTodayEarning();
    getTotalWithdrawal();
    getDirectRefCount();
  }, []);

  return (
    <div className="wrapper">
      <Header />
      <div className="d-flex align-items-stretch">
        <Sidebar />
        <div className="page-content">
          <div className="bg-dash-dark-2 py-4">
            <div className="container-fluid">
              <h2 className="h5 mb-0">Dashboard</h2>
            </div>
          </div>
          <section>
            <div className="container-fluid">
              <div className="row gy-4">
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="card mb-0 card_hover">
                    <div className="card-body">
                      <div className="d-flex align-items-end justify-content-between mb-2">
                        <div className="me-2">
                          {" "}
                          <span className="svg-icon svg-icon-sm svg-icon-heavy text-white mb-2">
                            <i className="ri-arrow-right-up-fill"></i>
                          </span>
                          <p className="text-sm text-uppercase text-gray-600 lh-1 mb-0">
                            MY DIRECT
                          </p>
                        </div>
                        <p className="text-xl lh-1 mb-0 text-dash-color-4">
                          {directRefCount}
                        </p>
                      </div>
                      <div className="progress" style={{ height: "3px" }}>
                        <div
                          className="progress-bar bg-dash-color-4"
                          role="progressbar"
                          style={{ width: "35%" }}
                          aria-valuenow="35"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="card mb-0 card_hover">
                    <div className="card-body">
                      <div className="d-flex align-items-end justify-content-between mb-2">
                        <div className="me-2">
                          {" "}
                          <span className="svg-icon svg-icon-sm svg-icon-heavy text-white mb-2">
                            <i className="ri-wallet-2-line"></i>
                          </span>
                          <p className="text-sm text-uppercase text-gray-600 lh-1 mb-0">
                            TOTAL INCOME
                          </p>
                        </div>
                        <p className="text-xl lh-1 mb-0 text-dash-color-4">
                          ${totalIncome}
                        </p>
                      </div>
                      <div className="progress" style={{ height: "3px" }}>
                        <div
                          className="progress-bar bg-dash-color-4"
                          role="progressbar"
                          style={{ width: "35%" }}
                          aria-valuenow="35"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="card mb-0 card_hover">
                    <div className="card-body">
                      <div className="d-flex align-items-end justify-content-between mb-2">
                        <div className="me-2">
                          {" "}
                          <span className="svg-icon svg-icon-sm svg-icon-heavy text-white mb-2">
                            <i className="ri-wallet-2-line"></i>
                          </span>
                          <p className="text-sm text-uppercase text-gray-600 lh-1 mb-0">
                            AVAILABLE BALANCE
                          </p>
                        </div>
                        <p className="text-xl lh-1 mb-0 text-dash-color-4">
                          ${currentBalance}
                        </p>
                      </div>
                      <div className="progress" style={{ height: "3px" }}>
                        <div
                          className="progress-bar bg-dash-color-4"
                          role="progressbar"
                          style={{ width: "35%" }}
                          aria-valuenow="35"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="card mb-0 card_hover">
                    <div className="card-body">
                      <div className="d-flex align-items-end justify-content-between mb-2">
                        <div className="me-2">
                          {" "}
                          <span className="svg-icon svg-icon-sm svg-icon-heavy text-white mb-2">
                            <i className="ri-wallet-2-line"></i>
                          </span>
                          <p className="text-sm text-uppercase text-gray-600 lh-1 mb-0">
                            TODAY EARNING
                          </p>
                        </div>
                        <p className="text-xl lh-1 mb-0 text-dash-color-4">
                          ${todayEarning}
                        </p>
                      </div>
                      <div className="progress" style={{ height: "3px" }}>
                        <div
                          className="progress-bar bg-dash-color-4"
                          role="progressbar"
                          style={{ width: "35%" }}
                          aria-valuenow="35"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="card mb-0 card_hover">
                    <div className="card-body">
                      <div className="d-flex align-items-end justify-content-between mb-2">
                        <div className="me-2">
                          {" "}
                          <span className="svg-icon svg-icon-sm svg-icon-heavy text-white mb-2">
                            <i className="ri-wallet-2-line"></i>
                          </span>
                          <p className="text-sm text-uppercase text-gray-600 lh-1 mb-0">
                            TOTAL WITHDRAWAL
                          </p>
                        </div>
                        <p className="text-xl lh-1 mb-0 text-dash-color-4">
                          ${totalWithdrawal}
                        </p>
                      </div>
                      <div className="progress" style={{ height: "3px" }}>
                        <div
                          className="progress-bar bg-dash-color-4"
                          role="progressbar"
                          style={{ width: "35%" }}
                          aria-valuenow="35"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <Link to="/staking_income" className="col-lg-4 col-md-6 col-sm-6">
                  <div className="card mb-0 card_hover">
                    <div className="card-body">
                      <div className="d-flex align-items-end justify-content-between mb-2">
                        <div className="me-2">
                          {" "}
                          <span className="svg-icon svg-icon-sm svg-icon-heavy text-white mb-2">
                            <i className="ri-wallet-2-line"></i>
                          </span>
                          <p className="text-sm text-uppercase text-gray-600 lh-1 mb-0">
                            Staking Income
                          </p>
                        </div>
                        <p className="text-xl lh-1 mb-0 text-dash-color-4">
                          0
                        </p>
                      </div>
                      <div className="progress" style={{ height: "3px" }}>
                        <div
                          className="progress-bar bg-dash-color-4"
                          role="progressbar"
                          style={{ width: "35%" }}
                          aria-valuenow="35"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/sponser_income" className="col-lg-4 col-md-6 col-sm-6">
                  <div className="card mb-0 card_hover">
                    <div className="card-body">
                      <div className="d-flex align-items-end justify-content-between mb-2">
                        <div className="me-2">
                          {" "}
                          <span className="svg-icon svg-icon-sm svg-icon-heavy text-white mb-2">
                            <i className="ri-wallet-2-line"></i>
                          </span>
                          <p className="text-sm text-uppercase text-gray-600 lh-1 mb-0">
                            Sponser Income
                          </p>
                        </div>
                        <p className="text-xl lh-1 mb-0 text-dash-color-4">
                          0
                        </p>
                      </div>
                      <div className="progress" style={{ height: "3px" }}>
                        <div
                          className="progress-bar bg-dash-color-4"
                          role="progressbar"
                          style={{ width: "35%" }}
                          aria-valuenow="35"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/matching_income" className="col-lg-4 col-md-6 col-sm-6">
                  <div className="card mb-0 card_hover">
                    <div className="card-body">
                      <div className="d-flex align-items-end justify-content-between mb-2">
                        <div className="me-2">
                          {" "}
                          <span className="svg-icon svg-icon-sm svg-icon-heavy text-white mb-2">
                            <i className="ri-wallet-2-line"></i>
                          </span>
                          <p className="text-sm text-uppercase text-gray-600 lh-1 mb-0">
                            Matching Income
                          </p>
                        </div>
                        <p className="text-xl lh-1 mb-0 text-dash-color-4">
                          0
                        </p>
                      </div>
                      <div className="progress" style={{ height: "3px" }}>
                        <div
                          className="progress-bar bg-dash-color-4"
                          role="progressbar"
                          style={{ width: "35%" }}
                          aria-valuenow="35"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/matching_level_income" className="col-lg-4 col-md-6 col-sm-6">
                  <div className="card mb-0 card_hover">
                    <div className="card-body">
                      <div className="d-flex align-items-end justify-content-between mb-2">
                        <div className="me-2">
                          {" "}
                          <span className="svg-icon svg-icon-sm svg-icon-heavy text-white mb-2">
                            <i className="ri-wallet-2-line"></i>
                          </span>
                          <p className="text-sm text-uppercase text-gray-600 lh-1 mb-0">
                            Matching Level Income
                          </p>
                        </div>
                        <p className="text-xl lh-1 mb-0 text-dash-color-4">
                          0
                        </p>
                      </div>
                      <div className="progress" style={{ height: "3px" }}>
                        <div
                          className="progress-bar bg-dash-color-4"
                          role="progressbar"
                          style={{ width: "35%" }}
                          aria-valuenow="35"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/reward_income" className="col-lg-4 col-md-6 col-sm-6">
                  <div className="card mb-0 card_hover">
                    <div className="card-body">
                      <div className="d-flex align-items-end justify-content-between mb-2">
                        <div className="me-2">
                          {" "}
                          <span className="svg-icon svg-icon-sm svg-icon-heavy text-white mb-2">
                            <i className="ri-wallet-2-line"></i>
                          </span>
                          <p className="text-sm text-uppercase text-gray-600 lh-1 mb-0">
                            Reward Income
                          </p>
                        </div>
                        <p className="text-xl lh-1 mb-0 text-dash-color-4">
                          0
                        </p>
                      </div>
                      <div className="progress" style={{ height: "3px" }}>
                        <div
                          className="progress-bar bg-dash-color-4"
                          role="progressbar"
                          style={{ width: "35%" }}
                          aria-valuenow="35"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/promotion_rank" className="col-lg-4 col-md-6 col-sm-6">
                  <div className="card mb-0 card_hover">
                    <div className="card-body">
                      <div className="d-flex align-items-end justify-content-between mb-2">
                        <div className="me-2">
                          {" "}
                          <span className="svg-icon svg-icon-sm svg-icon-heavy text-white mb-2">
                            <i className="ri-wallet-2-line"></i>
                          </span>
                          <p className="text-sm text-uppercase text-gray-600 lh-1 mb-0">
                            promotion Rank
                          </p>
                        </div>
                        <p className="text-xl lh-1 mb-0 text-dash-color-4">
                          0
                        </p>
                      </div>
                      <div className="progress" style={{ height: "3px" }}>
                        <div
                          className="progress-bar bg-dash-color-4"
                          role="progressbar"
                          style={{ width: "35%" }}
                          aria-valuenow="35"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </Link>
                <div className="col-md-6 col-sm-6">
                  <div className="card mb-0">
                    <div className="card-body py-3">
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <label className="mb-2"> Referral Link </label>
                          <div className="input-group">
                            <input
                              className="form-control"
                              id="inlineFormInputGroupUsername"
                              type="text"
                              value={`https://almightycrypto.info/signup?reffcode=${RefCode}`}
                            />

                            <CopyToClipboard text={`http://103.175.163.162:5083/signup?reffcode=${RefCode}`}>
                              <button type="button" className="btn btn-primary" onClick={() => alertSuccessMessage("Copied!!")}>Copy</button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </div>
  );
};
