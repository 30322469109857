import React, { useState, useEffect } from "react";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import AuthService from "../../api/services/AuthService";
import { Footer, Header, Sidebar } from "../../components";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../../components/CustomAlertMessage";
import LoaderHelper from "../../components/Loading/LoaderHelper";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import moment from "moment";

export const PackageJoiningReport = () => {
  const [data, setData] = useState([]);
  const { SearchBar } = Search;

  const dateFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <>{moment(row?.createdAt).format("DD MMM YYYY")}</>;
  };

  const packageFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <>{row?.packageName}</>;
  };

  const statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <>{row?.status}</>;
  };

  const columns = [
    {
      dataField: "sl.no",
      text: "SR No.",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1;
      },
      sort: true,
    },
    { dataField: "createdAt", text: "Date", formatter: dateFormatter },
    {
      dataField: "packageName",
      text: "Package Name",
      formatter: packageFormatter,
    },
    { dataField: "usdtAmount", text: "USDT Amount($)" },
    { dataField: "network", text: "Network" },
    { dataField: "hash", text: "Hash" },
    { dataField: "amountPaid", text: "Paid Amount($)" },
    { dataField: "remainingAmount", text: "Remaining Amount($)" },
    { dataField: "totalAmount", text: "Total Amount($)" },
    { dataField: "status", text: "Status", formatter: statusFormatter },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  useEffect(() => {
    handlePackageData();
  }, []);

  const handlePackageData = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.handlePackageData().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setData(result?.data);
          // alertSuccessMessage(result.message);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  return (
    <>
      <div className="wrapper">
        <Header />
        <div className="d-flex align-items-stretch">
          {/* Sidebar Navigation */}
          <Sidebar />
          <div className="page-content">
            {/* Page Header */}
            <div className="bg-dash-dark-2 py-4">
              <div className="container-fluid">
                <h2 className="h5 mb-0">Package Joining Report</h2>
              </div>
            </div>
            <div className="container-fluid py-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb py-3 px-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    My Package
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Package Joining Report
                  </li>
                </ol>
              </nav>
            </div>
            <section className="pt-0">
              <div className="container-fluid">
                <div className="row gy-4">
                  {/* Basic Form */}
                  {data?.length > 0 ? (
                    <div className="col-lg-12 m-auto">
                      <div className="card mb-0 h-100">
                        <div className="card-header pb-0 ">
                          {/* <div className=" row align-items-center mb-2"> */}
                          {/* <div className="col-md-7 col-lg-8">
                              <h3 className="h4 mb-0">Grand Total :- $0</h3>
                            </div> */}
                          {/* <div className="col-md-5 col-lg-4"></div> */}
                          {/* </div> */}
                        </div>
                        {/* <hr /> */}
                        <div className="card-body pt-0 py-0">
                          <div className="table-responsive">
                            <ToolkitProvider
                              hover
                              bootstrap4
                              keyField="_id"
                              columns={columns}
                              data={data}
                              exportCSV
                              search
                            >
                              {(props) => (
                                <React.Fragment>
                                  <SearchBar {...props.searchProps} />
                                  <BootstrapTable
                                    hover
                                    bootstrap4
                                    keyField="_id"
                                    columns={columns}
                                    data={data}
                                    pagination={pagination}
                                    filter={filterFactory()}
                                    {...props.baseProps}
                                  />
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <h5 className="text-center">No Data Available</h5>
                  )}
                </div>
              </div>
            </section>
            {/* Page Footer */}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
