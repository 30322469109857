import { Header, Sidebar, Footer } from "../../components";
import React, { useState } from "react";
import AuthService from "../../api/services/AuthService";
import { Link } from "react-router-dom";
import LoaderHelper from "../../components/Loading/LoaderHelper";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../../components/CustomAlertMessage";

export const Support = () => {
  const [userName, setUsername] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userContactNo, setUserContactNo] = useState();
  const [subject, setSubject] = useState();
  const [description, setDescription] = useState();
  const [isShow, setIsShow] = useState(false);
  const [localFrontImage, setLocalFrontImage] = useState("");
  const [localFront, setLocalFront] = useState("");

  const handleSupport = async (
    userName,
    userEmail,
    userContactNo,
    subject,
    description,
    localFront
  ) => {
    // var formData = new FormData();
    // formData.append("support_image", localFront);
    // formData.append("fullName", userName);
    // formData.append("mobileNumber", userContactNo);
    // formData.append("email", userEmail);
    // formData.append("subject", subject);
    // formData.append("description", description);
    LoaderHelper.loaderStatus(true);
    await AuthService.getSupport( userName,
      userEmail,
      userContactNo,
      subject,
      description,).then(async (result) => {
      console.log(result.success);
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          // alertSuccessMessage(result.message);
          setUsername("")
          setUserEmail("")
          setUserContactNo("")
          setSubject("")
          setDescription("")
          setIsShow(true);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const handleChangeIdentity = async (event) => {
    event.preventDefault();
    const fileUploaded = event.target.files[0];
    setLocalFrontImage(event.target.files[0].name);
    const imgata = URL.createObjectURL(fileUploaded);
    setLocalFront(fileUploaded);
  };

  return (
    <>
      <div className="wrapper">
        <Header />

        <div className="d-flex align-items-stretch">
          {/* Sidebar Navigation */}
          <Sidebar />

          <div className="page-content">
            {/* Page Header */}
            <div className="bg-dash-dark-2 py-4">
              <div className="container-fluid">
                <h2 className="h5 mb-0">Support</h2>{" "}
              </div>
            </div>
            <div className="container-fluid py-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb py-3 px-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Support
                  </li>
                </ol>
              </nav>
            </div>
            <section className="pt-0">
              <div className="container-fluid">
                <div className="row gy-4">
                  {/* Basic Form */}
                  <div className="col-lg-6 m-auto">
                    <div className="card mb-0 h-100">
                      <div className="card-header pb-0 ">
                        <div className="login-title text-center text-white">
                          <h2>Get Support!</h2>
                          <p>Enter details to connect with us.</p>
                        </div>
                      </div>
                      <div className="card-body py-0">
                        <form className="login-form py-4 w-100" method="get">
                          <div className="input-material-group mb-4">
                            <input
                              className="input-material"
                              id="login-username"
                              type="text"
                              name="loginUsername"
                              autoComplete="off"
                              required
                              data-validate-field="loginUsername"
                              onChange={(e) => setUsername(e.target.value)}
                            />
                            <label
                              className="label-material"
                              for="login-username"
                            >
                              Enter Name
                            </label>
                          </div>
                          <div className="input-material-group mb-4">
                            <input
                              className="input-material"
                              id="login-password"
                              type="text"
                              name="loginPassword"
                              autoComplete="off"
                              required
                              data-validate-field="loginPassword"
                              onChange={(e) => setUserEmail(e.target.value)}
                            />

                            <label
                              className="label-material"
                              for="login-password"
                            >
                              Enter Email
                            </label>
                          </div>
                          <div className="input-material-group mb-4">
                            <input
                              className="input-material"
                              id="login-password"
                              type="text"
                              name="loginPassword"
                              autoComplete="off"
                              required
                              data-validate-field="loginPassword"
                              onChange={(e) => setUserContactNo(e.target.value)}
                            />

                            <label
                              className="label-material"
                              for="login-password"
                            >
                              Enter Contact no.
                            </label>
                          </div>
                          <div className="input-material-group mb-4">
                            <input
                              className="input-material"
                              id="login-password"
                              type="text"
                              name="loginPassword"
                              autoComplete="off"
                              required
                              data-validate-field="loginPassword"
                              onChange={(e) => setSubject(e.target.value)}
                            />

                            <label
                              className="label-material"
                              for="login-password"
                            >
                              Enter Subject
                            </label>
                          </div>
                          <div className="input-material-group mb-4">
                            <textarea
                              className="input-material"
                              id="login-password"
                              type="text"
                              name="loginPassword"
                              autoComplete="off"
                              required
                              data-validate-field="loginPassword"
                              onChange={(e) => setDescription(e.target.value)}
                            />

                            <label
                              className="label-material"
                              for="login-password"
                            >
                              Enter Description
                            </label>
                          </div>
                          {/* <div className="input-material-group mb-4">
                            <label
                              className="label-material"
                              for="login-password"
                            >
                              Upload File
                            </label>
                            <div className="brows-file-wrapper">
                            
                              <input
                                name="file"
                                type="file"
                                required
                                className="inputfile"
                                onChange={handleChangeIdentity}
                                
                              />
                              
                            </div>
                          </div> */}
                          <button
                            className="btn btn-primary w-100 btn-block"
                            type="button"
                            onClick={() =>
                              handleSupport(
                                userName,
                                userEmail,
                                userContactNo,
                                subject,
                                description,
                              )
                            }
                          >
                            Submit
                          </button>

                          {/* <div className="follow_us mt-4">
                            <div className="social_icons align-items-center justify-content-start d-flex">
                              <label className="me-3 ">Follow us on: </label>
                              <a href="https://t.me/almightytokenio" className="">
                                <span className="btn btn-icon">
                                  <i className="ri-telegram-line"></i>
                                </span>
                              </a>
                              <a
                                href="https://www.instagram.com/almightytokenio/?igshid=YmMyMTA2M2Y%3D"
                                className=""
                              >
                                <span className="btn btn-icon me-0">
                                  <i className="ri-instagram-line"></i>
                                </span>
                              </a>
                            </div>
                          </div> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Page Footer */}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
