import { Sidebar, Header, Footer } from "../../components";
import React, { useEffect, useState } from "react";
import AuthService from "../../api/services/AuthService";
import { Link } from "react-router-dom";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../../components/CustomAlertMessage";
import "./index.css";
import LoaderHelper from "../../components/Loading/LoaderHelper";
import BootstrapTable from "react-bootstrap-table-next";
// import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { CSVLink } from "react-csv";

export const RankAchievementReport = () => {
  const [data, setData] = useState([]);

  const [isShow, setIsShow] = useState(false);

  const mainData = Object.entries(data);
  console.log(typeof mainData);
  console.log(mainData);

  // const columns = [
  //   {
  //     dataField: "criteria",
  //     text: "Criteria",
  //   },
  //   { dataField: "[1].directBonus", text: "Direct Bonus" },
  //   { dataField: "[1].Reward", text: "Reward" },
  //   { dataField: "[1].status", text: "Status" },
  // ];

  // const pagination = paginationFactory({
  //   page: 1,
  //   sizePerPage: 5,
  //   lastPageText: ">>",
  //   firstPageText: "<<",
  //   nextPageText: ">",
  //   prePageText: "<",
  //   showTotal: true,
  //   alwaysShowAllBtns: true,
  // });

  useEffect(() => {
    handleLifeTimeReward();
  }, []);

  const handleLifeTimeReward = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.lifeTimeReward().then(async (result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setData(result.data);
          alertSuccessMessage(result.message);
          setIsShow(true);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  return (
    <>
      <div className="wrapper">
        <Header />
        <div className="d-flex align-items-stretch">
          {/* Sidebar Navigation */}
          <Sidebar />
          <div className="page-content">
            {/* Page Header */}
            <div className="bg-dash-dark-2 py-4">
              <div className="container-fluid">
                <h2 className="h5 mb-0">Rank Achievement Report</h2>{" "}
              </div>
            </div>
            <div className="container-fluid py-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb py-3 px-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Income
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Rank Achievement Report
                  </li>
                </ol>
              </nav>
            </div>
            <section className="pt-0">
              <div className="container-fluid">
                <div className="row gy-4">
                  {/* data */}

                  {/* Basic Form */}

                  <div className="padding">
                    <div className="row d-flex justify-content-center">
                      <div className="card">
                        <div className="card-body">
                          {/* <h4 className="card-title">User Data</h4> */}
                          <div className="table-responsive">
                            <table className="table table-bordered serialNo">
                              <thead className="thead-light">
                                <tr>
                                  <th>SR No.</th>
                                  <th>Ranks</th>
                                  <th>Achieving Date</th>
                                  {/* <th>Status</th> */}
                                </tr>
                              </thead>
                              <tbody className="auto-index">
                                {mainData?.length > 0
                                  ? mainData.map((user) => {
                                      return (
                                        <tr>
                                          {/* <td>{serialNo.map((s) => s)}</td> */}
                                          <td></td>
                                          <td>{user[0]}</td>
                                          <td>{user[1].achievingDate}</td>
                                          {/* <td>{user[1].status}</td> */}
                                        </tr>
                                      );
                                    })
                                  : "No Data Available"}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Page Footer */}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
