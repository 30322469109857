import { Link } from "react-router-dom";
import { Header, Footer, Sidebar } from "../../components";
import React, { useState } from "react";
import { useData } from "../../context";
import AuthService from "../../api/services/AuthService";
import { useNavigate } from "react-router-dom";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../../components/CustomAlertMessage";
import LoaderHelper from "../../components/Loading/LoaderHelper";

export const ChangePassword = () => {
  const { email } = useData();
  const [isShow, setIsShow] = useState(false);
  const [otp, setOtp] = useState("");
  const [currentPass, setCurrentPass] = useState();
  const [newPass, setNewPass] = useState();
  const [confirmNewPass, setConfirmNewPass] = useState();

  const [timer, settimer] = useState(30);
  const [disableBtn, setdisableBtn] = useState(false);
  const navigate = useNavigate()

  var countdown;

  const handleGetChangePasswordOtp = async (email) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getChangePasswordOtp(email).then(async (result) => {
      console.log(result);
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          alertSuccessMessage(result.message);
          resendOtp();
          setIsShow(true);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const handleChangePassword = async (
    otp,
    currentPass,
    newPass,
    confirmNewPass
  ) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.changePassword(
      otp,
      currentPass,
      newPass,
      confirmNewPass
    ).then(async (result) => {
      console.log(result);
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          window.localStorage.clear()
          navigate("/login")
          alertSuccessMessage(result.message);
          setIsShow(true);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };
  const resendOtp = () => {
    setdisableBtn(true);
    countdown = setInterval(countDownFunc, 1000);
    setTimeout(() => {
      setdisableBtn(false);
      settimer(30);
      clearInterval(countdown);
    }, 30000);
  };
  const countDownFunc = () => {
    settimer((timer) => timer - 1);
  };

  return (
    <div className="wrapper">
      <Header />
      <div className="d-flex align-items-stretch">
        {/* Sidebar Navigation */}
        <Sidebar />

        <div className="page-content">
          {/* Page Header */}
          <div className="bg-dash-dark-2 py-4">
            <div className="container-fluid">
              <h2 className="h5 mb-0">Change Password</h2>{" "}
            </div>
          </div>
          <div className="container-fluid py-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb py-3 px-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Profile
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Change Password
                </li>
              </ol>
            </nav>
          </div>
          <div className="container-fluid">
            <div className="row gy-4">
              {/* Basic Form */}
              <div className="col-lg-6 m-auto">
                <div className="card  h-100">
                  <div className="card-header">
                    <h3 className="h4 mb-0">Change Password</h3>{" "}
                  </div>
                  <div className="card-body pt-0">
                    <form
                      onSubmit={(e) => {
                        {
                          e.preventDefault();
                          handleChangePassword(
                            otp,
                            currentPass,
                            newPass,
                            confirmNewPass
                          );
                        }
                      }}
                    >
                      <div className="mb-3">
                        {/* <label className="form-label" for="exampleInputOTP">
                          Enter LoginId
                        </label>
                        <input
                          className="form-control"
                          id="exampleInputOTP"
                          type="text"
                          aria-describedby="OTP"
                          value={loginId}
                          onChange={(e) => setLoginId(e.target.value)}
                        /> */}
                      </div>
                      <div className="mb-3">
                        <div className="form-group">
                          <label className="form-label" for="exampleInputOTP">
                            Enter OTP
                          </label>
                          <div className="input-group">
                            <input
                              className="form-control"
                              id="exampleInputOTP"
                              type="text"
                              aria-describedby="OTP"
                              value={otp}
                              autoComplete="off"
                              onChange={(e) => setOtp(e.target.value)}
                            />
                            {disableBtn ? (
                              <button
                                disabled
                                className="btn btn-primary btn-sm"
                                type="button"
                                style={{ color: "black" }}
                              >
                                Resend OTP {timer}
                              </button>
                            ) : (
                              <button
                                disabled={disableBtn ? true : false}
                                className="btn btn-primary btn-sm"
                                type="button"
                                onClick={() =>
                                  handleGetChangePasswordOtp(email)
                                }
                              >
                                Get Otp
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* {showTimer && <div>{counter}</div>}
                      <button onClick={() => counterHandler()}>Start</button> */}
                      <div className="mb-3">
                        <label className="form-label" for="exampleInputMember">
                          Enter Current password
                        </label>
                        <input
                          className="form-control"
                          id="exampleInputMember"
                          type="password"
                          autoComplete="off"
                          aria-describedby="Member"
                          value={currentPass}
                          onChange={(e) => setCurrentPass(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label" for="exampleInputEmail1">
                          Enter New Password
                        </label>
                        <input
                          className="form-control"
                          id="exampleInputEmail1"
                          type="password"
                          aria-describedby="Email"
                          value={newPass}
                          onChange={(e) => setNewPass(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label" for="exampleInputName">
                          Confirm New Password
                        </label>
                        <input
                          className="form-control"
                          id="exampleInputName"
                          type="password"
                          aria-describedby="Name"
                          value={confirmNewPass}
                          onChange={(e) => setConfirmNewPass(e.target.value)}
                        />
                      </div>

                      <button className="btn btn-primary" type="submit">
                        Change Password
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Page Footer */}
          <Footer />
        </div>
      </div>
    </div>
  );
};
